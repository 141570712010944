import React from 'react';

const PlayButton = () => {
  return (
    <svg
      width='24'
      height='26'
      viewBox='0 0 24 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
    >
      <g filter='url(#filter0_d_1045_3833)'>
        <path
          d='M20.6838 11.4171L5.42131 1.01119C5.1256 0.810301 4.76491 0.814637 4.47337 1.02059C4.17945 1.22871 4 1.60737 4 2.01927V22.8311C4 23.243 4.17945 23.6217 4.47337 23.8298C4.62242 23.9339 4.78816 23.9874 4.9539 23.9874C5.11964 23.9874 5.27644 23.9389 5.42131 23.8392L20.6838 13.4333C20.983 13.2273 21.1702 12.8436 21.1702 12.4252C21.1702 12.0068 20.983 11.6231 20.6838 11.4171Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_1045_3833'
          x='0'
          y='0.863281'
          width='25.1702'
          height='31.1241'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1045_3833'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1045_3833'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PlayButton;
