import { Button, Divider, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMessage,
  setRedirectionRoute,
  setSeeVideo,
  setTitleMessage,
} from "../redux/Reducer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import colors from "../assets/colors";
import jwtDecode from "jwt-decode";
import SignInIcon from "../assets/SignInIcon";
import ForgotPasswordIcon from "../assets/ForgotPasswordIcon";
import Lock from "../assets/Lock";

import validator from "validator";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = window.location.pathname;
  const search = window.location.search;
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { customerName } = useSelector((state) => state);

  useEffect(() => {
    params === `/${customerName}/resetPassword` && dispatch(setSeeVideo(true));
    setToken(search.split("=")[1]);
  }, [params]);

  useEffect(() => {
    token && setEmail(jwtDecode(token).email);
    token && console.log({ token2: jwtDecode(token) });
  }, [token]);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    axios
      .post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/auth/change-password`,
        {
          resetPwdToken: token,
          password: values.password,
          email: email,
        },
        { headers: { chinawall: process.env.REACT_APP_CHINAWALL } }
      )
      .then((res) => {
        // res && navigate(`/${customerName}/`);
        // res && message.success('Your password has changed successfully!')
        dispatch(setMessage("Your password has changed successfully!"));
        dispatch(setTitleMessage("Reset Password"));
        dispatch(setSeeVideo(false));
        dispatch(setRedirectionRoute("login"));
        navigate(`/${customerName}/feedback`);
        res && dispatch(setSeeVideo(false));
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const validatePassword = (rule, value, callback) => {
    const valid = validator.isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    if (!value || !valid) {
      callback(
        "Use an 8+ character combination of upper and lowercase letters, digits, and symbols."
      );
    } else {
      callback();
    }
  };

  return (
    <div className="main-container">
      <div className="header-page-title">THE SET</div>

      <div className="form-container">
        <div className="header-icon">
          <ForgotPasswordIcon />
        </div>
        <Form
          form={form}
          name="reset-password"
          onFinish={onFinish}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          className="login-form"
          scrollToFirstError
        >
          <div className="form-title">Reset Password</div>

          <Form.Item
            name="password"
            className="custom-form-item"
            rules={[{ validator: validatePassword }]}
            hasFeedback
          >
            <Input.Password
              className="custom-input"
              prefix={
                <div
                  style={{ marginRight: 10, display: "flex", padding: "3px" }}
                >
                  <Lock />
                </div>
              }
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            className="custom-input"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="custom-input"
              prefix={
                <div
                  style={{ marginRight: 10, display: "flex", padding: "3px" }}
                >
                  <Lock />
                </div>
              }
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Form.Item className="custom-form-button">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                <div className="login-form-button-text">Reset</div>
              </Button>
            </Form.Item>
          </div>
          <div className="register-text">
            <div>Already member? </div>
            <a
              href={`/${customerName}`}
              className="register-text-link"
              style={{
                color: colors.limed_spruce,
              }}
            >
              Sign In
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default ResetPassword;
