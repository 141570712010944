import { Divider, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Video from './VideoPreview';
import CustomTitle from './general/customTitle';
import SeeAll from './general/seeAll';
import SeeAllVideos from './SeeAllVideos';
import { useSelector } from 'react-redux';
import { intersection } from 'lodash';
import './styles.css';

const TimelineTagRelatedList = ({ data, title, vertical }) => {
  return (
    <div>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ paddingBottom: 10 }}
      >
        <CustomTitle title={title} />
        {data.length > 3 && <SeeAll hasTag title={title} />}
      </Row>
      <Row
        className='horizontal-list-row'
        style={{
          flexFlow: 'nowrap',
          overflowX: 'auto',
          width: '100%',
          paddingBottom: 20,
        }}
      >
        {data.slice(0, 3).map((article, idx, arr) => {
          return (
            <div key={article.id}>
              <Video
                hasTag
                title={title}
                vertical={vertical}
                article={article.attributes}
                index={idx}
                arr={arr}
              />
            </div>
          );
        })}
        {data.length > 3 && (
          <div>
            <SeeAllVideos hasTag vertical={true} title={title} />
          </div>
        )}
      </Row>
    </div>
  );
};

export default TimelineTagRelatedList;
