import { HomeFilled, UserOutlined } from '@ant-design/icons';
import { Divider, Menu, Anchor } from 'antd';
import GrapeIcon from '../assets/Grape';
import GlassIcon from '../assets/Glass';
import LogoGIcon from '../assets/LogoG';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomDrawer from './general/CustomDrawer';
import { useEffect, useState } from 'react';
import setImg from '../assets/images/loginLogo.png';
import {
  setUserToken,
  setCustomerRDX,
  setArticlesRDX,
  setSwimLanesRDX,
  setSelectedTag,
  setSelectedArticle,
} from '../redux/Reducer';
import timelineImg from '../assets/images/timelineOnly.png';
import './styles.css';

const getItem = (label, key, icon, disabled, children) => {
  return {
    key,
    icon,
    disabled,
    label,
    style: {
      margin: 0,
      width: '100%',
      color: '#343E47',
      borderRadius: 0,
    },
    children,
  };
};

const items = [
  getItem(
    <span
      style={{
        fontSize: 15,
        fontWeight: 700,
        color: '#343E47',
        paddingLeft: 20,
      }}
    >
      Home
    </span>,
    '1',
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 8.52951V21.8856C23.9983 23.3668 22.8056 24.567 21.3333 24.5686H16.8889C15.9072 24.5686 15.1111 23.7676 15.1111 22.7799V17.861C15.1111 17.3668 14.7133 16.9666 14.2222 16.9666H9.77778C9.28667 16.9666 8.88889 17.3668 8.88889 17.861V22.7799C8.88889 23.7676 8.09278 24.5686 7.11111 24.5686H2.66667C1.19444 24.567 0.00166667 23.3668 0 21.8856V8.52951C0.00111111 7.90122 0.328333 7.31933 0.863333 6.99457L11.0856 0.823525C11.6489 0.48367 12.3522 0.48367 12.9156 0.823525L23.1378 6.99457C23.6722 7.31933 23.9994 7.90122 24.0006 8.52951H24Z'
        fill='#343E47'
      />
    </svg>
  ),
  getItem(
    <span
      style={{
        fontSize: 15,
        fontWeight: 700,
        color: '#343E47',
        paddingLeft: 10,
      }}
    >
      Wine Growing Timeline
    </span>,
    '2',
    <img src={timelineImg} alt='' />
  ),
  // getItem(<span style={{ fontSize: 14, fontWeight: 700, }}>
  //   <span style={{ fontSize: 14, fontWeight: 700, color: '#BEBEBE', cursor: 'not-allowed' }}>Wine Making</span>
  //   <span style={{ backgroundColor: '#BEBEBE', color: '#fff', padding: '1px 5px', borderRadius: 4, marginLeft: '2%' }}> COMING SOON</span>
  // </span>, "3", <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path d="M10.981 12.3933C9.17935 12.3933 7.72948 11.0849 7.74611 9.48072C7.76275 7.86803 9.21262 6.58937 11.019 6.59576C12.7898 6.60214 14.2373 7.90633 14.2396 9.49561C14.2396 11.1019 12.7874 12.3955 10.9834 12.3933H10.981Z" fill="#BEBEBE" />
  //   <path d="M18.7414 12.3933C16.9397 12.3933 15.4899 11.0849 15.5065 9.48072C15.5231 7.86803 16.973 6.58937 18.7794 6.59576C20.5501 6.60214 21.9976 7.90633 22 9.49561C22 11.1019 20.5478 12.3955 18.7437 12.3933H18.7414Z" fill="#BEBEBE" />
  //   <path d="M7.10196 18.459C5.30032 18.459 3.85045 17.1505 3.86709 15.5464C3.88372 13.9337 5.33359 12.655 7.13999 12.6614C8.91073 12.6678 10.3582 13.972 10.3606 15.5613C10.3606 17.1676 8.90835 18.4611 7.10434 18.459H7.10196Z" fill="#BEBEBE" />
  //   <path d="M10.981 24.5268C9.17935 24.5268 7.72948 23.2183 7.74611 21.6141C7.76275 20.0015 9.21262 18.7228 11.019 18.7292C12.7898 18.7356 14.2373 20.0398 14.2396 21.629C14.2396 23.2353 12.7874 24.5289 10.9834 24.5268H10.981Z" fill="#BEBEBE" />
  //   <path d="M14.8647 18.459C13.0631 18.459 11.6132 17.1505 11.6298 15.5464C11.6465 13.9337 13.0963 12.655 14.9027 12.6614C16.6735 12.6678 18.121 13.972 18.1234 15.5613C18.1234 17.1676 16.6711 18.4611 14.8671 18.459H14.8647Z" fill="#BEBEBE" />
  //   <path d="M3.24201 12.3933C1.42848 12.3954 0 11.1168 0 9.49559C0 7.87865 1.44036 6.59361 3.24914 6.59787C5.02939 6.59999 6.46975 7.88929 6.47688 9.48283C6.48163 11.1019 5.0484 12.3933 3.24438 12.3954L3.24201 12.3933Z" fill="#BEBEBE" />
  //   <path d="M3.75778 0.211358C3.78868 0.434751 3.80294 0.630486 3.84572 0.821966C4.29732 2.78995 5.61171 4.04946 7.76751 4.59412C9.18648 4.95367 10.6292 4.9558 12.0743 4.73666C14.5272 4.36222 16.4453 3.26014 17.7312 1.33683C17.9689 0.981532 18.1471 0.596445 18.3658 0.202848C18.2351 0.177317 18.1614 0.158169 18.0854 0.147531C17.1917 0.00924023 16.2932 -0.037566 15.3876 0.0411535C14.5177 0.117745 13.6906 0.31348 12.9443 0.738991C11.8509 1.36236 11.0499 2.23041 10.3796 3.20695C10.3535 3.24312 10.3226 3.27716 10.2632 3.35375C8.58514 1.50066 6.28674 0.707078 3.75778 0.211358Z" fill="#BEBEBE" />
  //   <path d="M3.75778 0.210944C6.28674 0.706665 8.58514 1.50024 10.2656 3.35121C10.325 3.27675 10.3559 3.24271 10.382 3.20441C11.0523 2.22787 11.8533 1.35982 12.9466 0.73645C13.6906 0.31094 14.5201 0.115205 15.39 0.0386126C16.2956 -0.0401069 17.194 0.00457177 18.0877 0.14499C18.1638 0.157756 18.2375 0.174776 18.3682 0.200307C18.1495 0.593904 17.9713 0.978991 17.7336 1.33429C16.4477 3.25973 14.5296 4.3618 12.0767 4.73412C10.6316 4.95539 9.18885 4.95113 7.76988 4.59158C5.61409 4.0448 4.2997 2.78528 3.8481 0.819425C3.80532 0.627945 3.79106 0.43221 3.76016 0.208817L3.75778 0.210944Z" fill="#BEBEBE" />
  // </svg>),
  // getItem(<span style={{ fontSize: 14, fontWeight: 700, paddingLeft: 5, cursor: 'not-allowed' }}>
  //   <span style={{ fontSize: 14, fontWeight: 700, color: '#BEBEBE', }}>Wine Tasting</span>
  //   <span style={{ backgroundColor: '#BEBEBE', color: '#fff', padding: '1px 5px', borderRadius: 4, marginLeft: '2%' }}> COMING SOON</span>
  // </span>, "4", <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 4 }}>
  //   <path d="M10.5 22.915H8.16689V14.4919C11.4769 13.9957 14.0007 11.4322 14.0007 8.33516C14.0007 5.23816 13.3335 2.56964 12.1386 0H1.86143C0.666504 2.56964 0 5.38343 0 8.33578C0 11.4322 2.52383 13.9963 5.83311 14.4925V22.9168H3.5C2.21143 22.9168 1.16689 23.8495 1.16689 25L12.8338 24.9988C12.8338 23.8482 11.7893 22.9156 10.5 22.9156V22.915Z" fill="#BEBEBE" />
  // </svg>),
  // getItem(
  //   "Wine Making (COMING SOON)",
  //   "3",
  //   <span>
  //     <GrapeIcon />
  //   </span>,
  //   true
  // ),
  // getItem(
  //   "Wine Tasting (COMING SOON)",
  //   "4",
  //   <span>
  //     <GlassIcon />
  //   </span>,
  //   true
  // ),
  getItem(
    <span
      style={{
        fontSize: 15,
        fontWeight: 700,
        color: '#343E47',
        paddingLeft: 20,
      }}
    >
      Logout
    </span>,
    '3',
    <svg
      width='21'
      height='23'
      viewBox='0 0 21 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6583 10.7522C8.9927 10.7522 7.55029 10.2225 6.37139 9.17707C5.19298 8.13206 4.59566 6.85338 4.59566 5.37589C4.59566 3.8984 5.19298 2.62015 6.37188 1.57471C7.55029 0.529698 8.9927 0 10.6583 0C12.3239 0 13.7663 0.529698 14.9447 1.57471C16.1231 2.61972 16.721 3.89884 16.721 5.37589C16.721 6.85294 16.1231 8.13206 14.9447 9.17751C13.7658 10.2225 12.3239 10.7522 10.6583 10.7522Z'
        fill='#343E47'
      />
      <path
        d='M0.0501423 17.164C0.0840645 16.7289 0.152891 16.2545 0.254164 15.7536C0.356421 15.2488 0.487684 14.7718 0.645494 14.3354C0.80822 13.8846 1.02945 13.4395 1.30279 13.0127C1.58645 12.5698 1.91977 12.1844 2.29389 11.867C2.68522 11.5352 3.16406 11.2684 3.71762 11.0735C4.26971 10.88 4.88128 10.7819 5.53563 10.7819C5.79275 10.7819 6.04101 10.8752 6.52083 11.1524C6.8163 11.3233 7.1619 11.5208 7.54733 11.7392C7.87721 11.9254 8.3236 12.1002 8.87569 12.258C9.41401 12.4124 9.96069 12.4909 10.5 12.4909C11.0393 12.4909 11.586 12.4124 12.1248 12.258C12.6759 12.1002 13.1228 11.9258 13.4522 11.7392C13.8342 11.523 14.1798 11.3251 14.4797 11.152C14.959 10.8747 15.2077 10.7814 15.4649 10.7814C16.1192 10.7814 16.7308 10.8795 17.2824 11.0735C17.8359 11.268 18.3148 11.5348 18.7066 11.867C19.0807 12.1844 19.414 12.5698 19.6977 13.0123C19.971 13.4391 20.1923 13.8838 20.355 14.335C20.5123 14.771 20.6441 15.2483 20.7458 15.7527C20.8471 16.2532 20.9159 16.7276 20.9499 17.1635C20.9833 17.5908 21 18.0337 21 18.4815C21 19.6468 20.5821 20.5898 19.7587 21.2856C18.945 21.9722 17.8689 22.3201 16.5597 22.3201H4.43981C3.13112 22.3201 2.05497 21.9722 1.24134 21.2856C0.417383 20.5902 -1.90735e-06 19.6468 -1.90735e-06 18.4815C-1.90735e-06 18.0315 0.0172043 17.5882 0.0511265 17.1631L0.0501423 17.164Z'
        fill='#343E47'
      />
    </svg>
  ),
  getItem(
    <span
      style={{
        fontSize: 15,
        fontWeight: 700,
        color: '#343E47',
        paddingLeft: 20,
      }}
    >
      Video Demo
    </span>,
    '4',
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg>
  ),
];

const SiderContent = ({ setMenuOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [selectedKey, setSelectedKey] = useState([items[0].key]);
  const { customerRDX, customerName } = useSelector((state) => state);

  // const showDrawer = () => {
  //   setMenuOpen(true);
  // };

  // const onClose = () => {
  // };

  const logout = () => {
    dispatch(setUserToken(null));
    navigate('/' + customerName + '/');
    dispatch(setCustomerRDX(null));
    dispatch(setSwimLanesRDX([]));
    dispatch(setArticlesRDX([]));
    dispatch(setSelectedArticle(null));
    dispatch(setSelectedTag([]));
    localStorage.clear();
  };

  const _onCLick = (e) => {
    if (e.key === '1' && customerRDX) {
      setSelectedKey([e.key]);
      navigate('/' + customerRDX.slug + '/latest');
    } else if (e.key === '2' && customerRDX) {
      setSelectedKey([e.key]);
      navigate('/' + customerRDX.slug + '/timeline');
    } else if (e.key === '3') {
      logout();
    }else if (e.key === '4') {
      setSelectedKey([e.key]);
      navigate('/' + customerRDX.slug + '/video');
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    const suffix = pathname.split('/')[2];
    if (suffix === 'timeline') setSelectedKey(items[1].key);
    else setSelectedKey(items[0].key);
  }, [pathname]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '1px solid #EEEEEE',
          }}
        >
          <span
            style={{
              fontWeight: '400',
              textAlign: 'center',
              letterSpacing: '0.35em',
              color: '#CCB68C',
              cursor: 'pointer',
              padding: 5,
            }}
            onClick={() => {
              navigate('/' + customerRDX.slug + '/latest');
            }}
          >
            <img
              src={setImg}
              width={'55%'}
              style={{ position: 'relative', top: 5 }}
            />
          </span>
        </div>

        <Menu
          onClick={_onCLick}
          selectedKeys={selectedKey}
          mode='vertical'
          theme='light'
          items={items}
          className='side-menu-container'
          style={{
            backgroundColor: '#fff',
            border: 0,
          }}
        />
        {/* <CustomDrawer onClose={onClose} open={open} setMenuOpen={setMenuOpen} /> */}
      </div>

      <div
        style={{ flexGrow: '0.1', display: 'flex', flexDirection: 'column' }}
      >
        <Divider style={{ margin: 0, backgroundColor: '#CCCCCC' }} />

        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            padding: 10,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LogoGIcon />
        </div>
      </div>

      
    </div>
  );
};

export default SiderContent;
