import React from 'react';
import { Card, Row, message } from 'antd';
import PlayButton from '../assets/PlayButton';
import colors from '../assets/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSeeVideo,
  setSelectedArticle,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
  setSwimlaneArticleIndex,
  setSwimlaneArticles,
} from '../redux/Reducer';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { findTime } from '../helpers/findTime';
import { SmallDashOutlined } from '@ant-design/icons';

const VideoPreview = ({ hasTag, vertical, article, title, index, arr }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerName, selectedTag } = useSelector((state) => state);

  return (
    <Card
      hoverable
      style={{
        position: 'relative',
        borderRadius: 10,
        overflow: 'hidden',
        display: 'flex',
        width: 240,
        height: 240,
        backgroundImage: `linear-gradient(to bottom, transparent, transparent, #343E47), url(${article.cover.data.attributes.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginRight: 10,
      }}
      onClick={() => {
        dispatch(setSeeVideo(true));
        const tagToSelect = hasTag ? article.tags.data[0].attributes : title;
        dispatch(
          setSelectedTagForPreviousNavigation(hasTag ? selectedTag : null)
        );
        dispatch(setSelectedTag(tagToSelect));
        dispatch(
          setSelectedArticle({
            ...article,
            time: findTime(moment(article?.createdAt)),
          })
        );
        article.video.data
          ? navigate(`/${customerName}/article/${article.slug}`, {
              state: hasTag ? { goToPreviousNavigationTag: true } : {},
            })
          : message.error('There is no content for this article');
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PlayButton />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '31%',
          textAlign: 'start',
          paddingLeft: 12,
        }}
      >
        {article.createdAt && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ color: '#fff', fontSize: 10, fontWeight: 400 }}>
              {findTime(moment(article.createdAt))}
            </div>
            {article.tags.data.map((x, idx) => (
              <span
                style={{
                  backgroundColor: colors[x.attributes.color],
                  color: '#fff',
                  borderRadius: 2,
                  padding: '0px 5px',
                  fontSize: 10,
                  fontWeight: 700,
                  marginLeft: 5,
                  textTransform: 'uppercase',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/${customerName}/tags/${x.attributes.name
                      .split(' ')
                      .map((x) => x.toLowerCase())
                      .join('-')}`
                  );
                  dispatch(setSeeVideo(true));
                  dispatch(
                    setSelectedTagForPreviousNavigation(
                      hasTag ? selectedTag : null
                    )
                  );
                  dispatch(setSelectedTag(x.attributes));
                }}
              >
                {x.attributes.name}
              </span>
            ))}
          </div>
        )}

        <Row>
          <div
            style={{
              fontSize: 14,
              color: '#fff',
              fontFamily: 'SF Pro Display Medium',
              paddingTop: 3,
              lineHeight: 1.6,
            }}
          >
            {article.name}
          </div>
        </Row>
      </div>
    </Card>
  );
};

export default VideoPreview;
