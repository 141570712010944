import { Card, Tag } from 'antd';
import React, { useState } from 'react';
import PlayButton from '../assets/PlayButton';
import colors from '../assets/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSeeVideo,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
} from '../redux/Reducer';
import { useNavigate } from 'react-router-dom';

const SeeAllVideos = ({ hasTag, vertical, time, title, desc }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerName, selectedTag } = useSelector((state) => state);

  return (
    <Card
      hoverable
      style={{
        position: 'relative',
        borderRadius: 10,
        overflow: 'hidden',
        display: 'flex',
        width: 240,
        height: 240,
        backgroundColor: colors.limed_spruce,
      }}
      onClick={() => {
        dispatch(setSeeVideo(true));
        if (hasTag) {
          navigate(
            `/${customerName}/tags/${selectedTag.tag.data.attributes.name
              .split(' ')
              .map((x) => x.toLowerCase())
              .join('-')}`
          );
          dispatch(setSelectedTagForPreviousNavigation(selectedTag));
          dispatch(setSelectedTag(selectedTag.tag.data.attributes));
        } else {
          dispatch(setSelectedTag(title));
          navigate(
            `/${customerName}/swimlanes/${title
              .split(' ')
              .map((x) => x.toLowerCase())
              .join('-')}`
          );
        }
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 80,
          right: 80,
          backgroundColor: '#D9D9D9',
          opacity: 0.8,
          padding: 24,
          borderRadius: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PlayButton />
      </div>

      <span
        style={{
          position: 'absolute',
          top: 180,
          left: 70,
          color: '#fff',
          fontSize: 18,
          fontWeight: 600,
        }}
      >
        See all videos
      </span>
    </Card>
  );
};

export default SeeAllVideos;
