import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import moment from 'moment';
import { monthGrids, colorMatch, iconMatch } from './constants';
// Components
import ListVideos from '../components/List';
import BrowseTags from '../components/BrowseTags';
import VerticalVideos from '../components/VerticalList';
import HorizontalList from '../components/HorizontalList';
import Timeline from '../components/Timeline';
import { setCustomerRDX, setSeeVideo } from '../redux/Reducer';
import timelineImg from '../assets/images/timelineOnly.png';
import rightArrowImg from '../assets/images/right-arrow.png';
import './styles.css';
import { useNavigate } from 'react-router-dom';

const Latest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { swimlanesRDX, customerRDX, customerName, timelineRDX } = useSelector(
    (state) => state
  );
  const [verticalSwimlanes, setverticalSwimlanes] = useState([]);
  const [horizontalSwimlanes, sethorizontalSwimlanes] = useState([]);
  const [smallSwimlanes, setsmallSwimlanes] = useState([]);

  useEffect(() => {
    dispatch(setSeeVideo(false));
  }, []);

  useEffect(() => {
    if (swimlanesRDX && swimlanesRDX.length > 0) {
      setverticalSwimlanes(
        swimlanesRDX?.filter(
          (x) =>
            x.attributes.parent.data &&
            x.attributes.parent.data.attributes.name &&
            x.attributes.articles.data.length > 0 &&
            x.attributes.type.includes('vertical')
        )
      );

      sethorizontalSwimlanes(
        swimlanesRDX?.filter(
          (x) =>
            x.attributes.parent.data &&
            x.attributes.parent.data.attributes.name &&
            x.attributes.articles.data.length > 0 &&
            x.attributes.type.includes('horizontal')
        )
      );

      setsmallSwimlanes(
        swimlanesRDX?.filter(
          (x) =>
            x.attributes.parent.data &&
            x.attributes.parent.data.attributes.name &&
            x.attributes.articles.data.length > 0 &&
            x.attributes.type.includes('small')
        )
      );
    }
  }, [swimlanesRDX]);

  return (
    <div>
      <div style={{ padding: '0px 12px' }}>
        {verticalSwimlanes.map((sl) => (
          <VerticalVideos
            key={sl.attributes.createdAt}
            color={sl.attributes.color}
            title={sl.attributes.name}
            time={sl.attributes.createdAt}
            data={verticalSwimlanes?.map((a) => a.attributes.articles.data)}
            vertical={true}
          />
        ))}
        {horizontalSwimlanes.map((sl) => (
          <HorizontalList
            key={sl.attributes.createdAt}
            color={sl.attributes.color}
            title={sl.attributes.name}
            time={sl.attributes.createdAt}
            data={horizontalSwimlanes
              ?.filter((x) => x.attributes.articles.data.length > 0)
              ?.map((a) => a.attributes.articles.data)}
            vertical={false}
          />
        ))}
        {smallSwimlanes.map((sl) => (
          <ListVideos
            key={sl.attributes.createdAt}
            color={sl.attributes.color}
            title={sl.attributes.name}
            time={sl.attributes.createdAt}
            data={smallSwimlanes?.map((a) => a.attributes.articles.data)}
            vertical={false}
          />
        ))}
        <BrowseTags />
        <br />
      </div>
      {/*
      // TODO: timeline temporarily disabled
      <div style={{ paddingBottom: 30 }}>
        <Timeline />
      </div> */}
    </div>
  );
};

export default Latest;
