import { Button } from "antd";
import React from "react";
import colors from "../assets/colors";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSeeVideo } from "../redux/Reducer";

const BrowseTags = () => {
  const { customerName } = useSelector((state) => state);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center" }}>
      <span
        style={{ fontWeight: 600, fontSize: 18, color: colors.limed_spruce }}
      >
        Looking for something specific?
      </span>
      <br />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          type="primary"
          style={{
            borderRadius: 25,
            padding: "8px 23px",
            margin: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            navigate(`/${customerName}/allTags`);
            dispatch(setSeeVideo(true));
          }}
        >
          Browse All Tags
        </Button>
      </div>
    </div>
  );
};

export default BrowseTags;
