import React, { useEffect, useRef, useState } from "react";
import { Menu, Drawer, Layout, Col } from "antd";
import RouterNavigator from "../router";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setBaseAuthURL,
  setBaseURL,
  setChinaWall,
  setCustomerName,
  setToken,
} from "../redux/Reducer";
import {
  fetchSwimlanes,
  fetchCustomers,
  fetchArticles,
  fetchTimeline,
} from "../redux/thunks";
import Sider from "antd/es/layout/Sider";
import SiderContent from "../components/SiderContent";
import { intersection } from "lodash";
import colors from "../assets/colors";
import { MenuOutlined } from "@ant-design/icons";
import defaultBannerImg from "../assets/images/default-banner.png";
import { inlineMenuItems } from "./constants";

import "./styles.css?v=1.2";
import { Row } from "antd";
import Footer from "../components/footer/Footer";

const { Content } = Layout;

const LayOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const {
    seeVideo,
    token,
    baseUrl,
    customerRDX,
    swimlanesRDX,
    articlesRDX,
    userToken,
    customerName,
  } = useSelector((state) => state);

  const [swimlanesRoutes, setSwimLanesRoutes] = useState([]);
  const [slRoutes, setSlRoutes] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [inlineMenuRouteItems, setInlineMenuRouteItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [custName, setCustName] = useState(null);
  const apiCallsFetchedRef = useRef(false);

  // const allTagsMatch = useMatch(`${customerName}/allTags`);
  // const tagMatch = useMatch(`${customerName}/tags/*`);
  // const swimlanesMatch = useMatch(`${customerName}/swimlanes/*`);
  const videoMatch = useMatch(`/${customerName}/article/*`);
  const timelineRouteMatch = useMatch(`/${customerName}/timeline`);

  // initial auth setup
  useEffect(() => {
    setCustName(pathname.split("/")[1]);
    dispatch(setCustomerName(pathname.split("/")[1]));
    dispatch(setToken(process.env.REACT_APP_TOKEN));
    dispatch(setBaseURL(process.env.REACT_APP_BASE_URL));
    dispatch(setBaseAuthURL(process.env.REACT_APP_AUTH_BASE_URL));
    dispatch(setChinaWall(process.env.REACT_APP_CHINAWALL));
  }, []);

  // initial data fetching
  useEffect(() => {
    if (userToken && token && custName && !apiCallsFetchedRef.current) {
      dispatch(fetchCustomers({ baseUrl, token, custName }));
      dispatch(fetchSwimlanes({ baseUrl, token }));
      dispatch(fetchArticles({ baseUrl, token }));
      dispatch(fetchTimeline({ baseUrl, token }));
      apiCallsFetchedRef.current = true;
    }
  }, [custName, token, userToken]);

  useEffect(() => {
    if (!customerRDX || !swimlanesRDX.length || !articlesRDX.length) return;

    if ((swimlanesRDX || []).length) {
      const slugRoutes = intersection(
        // get all the swimlanes which have articles and match them with the below
        swimlanesRDX
          .filter(
            (sln) =>
              sln.attributes.parent.data &&
              sln.attributes.articles.data.length > 0
          )
          .map((sl) => sl.attributes.parent.data.attributes.slug),
        intersection(
          // get all the swimlanes from customer's endpoint and match them
          swimlanesRDX
            .filter((sln) => !sln.attributes.parent.data)
            .map((sl) => sl.attributes.slug),
          customerRDX.swimlanes?.data
            ?.filter((x) => x.attributes.publishedAt)
            ?.map((sl) => sl.attributes.slug)
        )
      );
      setSwimLanesRoutes(slugRoutes);
      setSlRoutes(
        swimlanesRDX.filter((x) => slugRoutes.includes(x.attributes.slug))
      );
    }
  }, [customerRDX, swimlanesRDX, articlesRDX]);

  // inline route menu items setup
  useEffect(() => {
    if (customerName) {
      const suffix = pathname.split("/")[2];
      setInlineMenuRouteItems(
        inlineMenuItems.map((item) => ({
          ...item,
          path: `${customerName}/${item.key}`,
          selected: suffix === item.key ? true : false,
        }))
      );
    }
  }, [customerName]);

  // inline route menu items selection change upon navigation
  useEffect(() => {
    const suffix = pathname.split("/")[2];
    if (suffix === "timeline")
      setInlineMenuRouteItems((prev) =>
        prev.map((i) =>
          i.key === suffix
            ? { ...i, selected: true }
            : { ...i, selected: false }
        )
      );
    else
      setInlineMenuRouteItems((prev) =>
        prev.map((i) =>
          i.key !== "timeline"
            ? { ...i, selected: true }
            : { ...i, selected: false }
        )
      );
  }, [pathname]);

  // inline filter items setup
  useEffect(() => {
    if (customerName && slRoutes.length && !seeVideo) {
      const suffix = pathname.split("/")[2];

      setFilterItems([
        {
          label: <span>Latest</span>,
          key: `${customerName}/latest`,
          selected: suffix === "latest",
        },
        ...slRoutes?.map((sl) => ({
          label: <span>{sl.attributes.name}</span>,
          key: `${customerName}/${sl.attributes?.slug}`,
          selected: suffix === sl.attributes?.slug,
        })),
      ]);
    }
  }, [pathname, customerName, slRoutes, seeVideo]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return userToken ? (
    <Layout style={{ height: "100vh", backgroundColor: "rgb(254,252,251)" }}>
      <Sider
        width={282}
        breakpoint="lg"
        theme="light"
        style={{
          display: window.innerWidth > 765 ? "block" : "none",
          backgroundColor: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 6px",
        }}
      >
        <SiderContent setMenuOpen={setMenuOpen} />
      </Sider>
      <Layout
        style={{
          maxWidth: 696,
          width: !userToken ? 500 : null,
          margin: "0 auto",
        }}
      >
        <Content
          style={{
            backgroundColor: !!videoMatch ? "rgb(254, 252, 251)" : "#fff",
            overflow: "initial",
            overflowX: "hidden",
            height: "100%",
            paddingBottom: 68,
            boxShadow: !!videoMatch ? "" : "rgba(0, 0, 0, 0.1) 0px 2px 6px",
            position: "relative",
          }}
        >
          {!seeVideo && (
            // {!seeVideo && !allTagsMatch && !tagMatch && !swimlanesMatch && (
            <>
              {/* header */}
              <div
                className="header"
                style={
                  customerRDX && {
                    backgroundColor: "#fff",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${
                      customerRDX?.cover?.data?.attributes?.formats?.large
                        ?.url || defaultBannerImg
                    })`,
                    zIndex: 1,
                    padding: 8,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }
                }
              >
                {window.innerWidth < 1000 && (
                  <div
                    style={{
                      position: "relative",
                      float: "left",
                      width: 50,
                      height: 50,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div onClick={() => setMenuOpen(true)}>
                      <MenuOutlined
                        style={{ color: "#fff", fontSize: 25, fontWeight: 700 }}
                      />
                    </div>
                    <Drawer
                      rootClassName="side-drawer"
                      closeIcon={
                        <span
                          style={{
                            position: "absolute",
                            left: "73%",
                            top: "1%",
                            color: "#fff",
                            fontWeight: 700,
                            fontSize: 24,
                          }}
                        >
                          X
                        </span>
                      }
                      placement="left"
                      onClose={() => setMenuOpen(false)}
                      open={menuOpen}
                      setOpen={setMenuOpen}
                      bodyStyle={{ padding: 0 }}
                      style={{
                        backgroundColor: "#fff",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <SiderContent setMenuOpen={setMenuOpen} />
                    </Drawer>
                  </div>
                )}
              </div>

              {/* inline route menu items */}
              <div
                style={{
                  height: 60,
                  borderBottom: "3px solid #e9e9e9",
                }}
              >
                <Row style={{ height: "inherit" }}>
                  {inlineMenuRouteItems.map((i) => (
                    <Col
                      onClick={() => {
                        navigate(i.path);
                        setInlineMenuRouteItems((prev) =>
                          prev.map((prevI) =>
                            prevI.key === i.key
                              ? { ...prevI, selected: true }
                              : { ...prevI, selected: false }
                          )
                        );
                      }}
                      key={i.key}
                      xs={12}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: i.selected ? "5px solid #ae7575" : "",
                      }}
                    >
                      {i.icon}
                    </Col>
                  ))}
                </Row>
              </div>

              {/* inline filter items */}
              {!timelineRouteMatch && (
                <div
                  style={{
                    zIndex: 20,
                    backgroundColor: "#fff",
                    position: "sticky",
                    top: 0,
                    overflowX: "auto",
                    display: "flex",
                    flexWrap: "nowrap",
                    backgroundColor: "#fff",
                    padding: "10px 0 10px 6px",
                  }}
                >
                  {filterItems.map((item, idx) => (
                    <span
                      key={idx}
                      style={{
                        padding: 6,
                        fontWeight: 700,
                        fontSize: 16,
                        cursor: "pointer",
                        color: item.selected ? "#000" : "#343E47",
                        opacity: item.selected ? 1 : 0.5,
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        navigate(item.key);
                        setFilterItems((prev) =>
                          prev.map((o, id) =>
                            id === idx
                              ? { ...o, selected: true }
                              : { ...o, selected: false }
                          )
                        );
                      }}
                    >
                      {item.label}
                    </span>
                  ))}
                </div>
              )}
            </>
          )}
          <div>
            <RouterNavigator slug={customerName} swimlanes={swimlanesRoutes} />
          </div>
        </Content>
      </Layout>
    </Layout>
  ) : (
    <div style={{ display: "flex" }}>
      {/* {window.innerWidth > 768 && ( */}
      <div
        style={{
          width: "100%",
          backgroundImage: `url(${require("../assets/images/new-login-logo.jpeg")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <RouterNavigator />
        <div>
          <Footer />
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default LayOut;
