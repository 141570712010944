import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VerticalVideos from '../components/VerticalList';
import HorizontalList from '../components/HorizontalList';
import { Divider } from 'antd';
import ListVideos from '../components/List';
import BrowseTags from '../components/BrowseTags';
import { setSeeVideo } from '../redux/Reducer';

const WineryUpdate = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSeeVideo(false));
  }, []);

  const params = window.location.pathname.split('/')[2];
  const { swimlanesRDX } = useSelector((state) => state);

  return (
    <div style={{ padding: '0px 12px' }}>
      {swimlanesRDX
        .filter(
          (x) =>
            x.attributes.articles.data.length > 0 &&
            x.attributes.parent.data &&
            x.attributes.parent.data.attributes.slug === params &&
            x.attributes.type.includes('vertical')
        )
        .map((sl) => (
          <VerticalVideos
            color={sl.attributes.color}
            title={sl.attributes.name}
            time={sl.attributes.createdAt}
            data={swimlanesRDX
              .filter(
                (x) =>
                  x.attributes.parent.data &&
                  x.attributes.parent.data.attributes.slug === params &&
                  x.attributes.type.includes('vertical')
              )
              .map((a) => a.attributes.articles.data)}
            vertical={true}
          />
        ))}
      {swimlanesRDX
        .filter(
          (x) =>
            x.attributes.articles.data.length > 0 &&
            x.attributes.parent.data &&
            x.attributes.parent.data.attributes.slug === params &&
            x.attributes.type.includes('horizontal')
        )
        .map((sl) => (
          <HorizontalList
            color={sl.attributes.color}
            title={sl.attributes.name}
            time={sl.attributes.createdAt}
            data={swimlanesRDX
              .filter(
                (x) =>
                  x.attributes.parent.data &&
                  x.attributes.parent.data.attributes.slug === params &&
                  x.attributes.type.includes('horizontal')
              )
              .map((a) => a.attributes.articles.data)}
            vertical={false}
          />
        ))}
      {swimlanesRDX
        .filter(
          (x) =>
            x.attributes.articles.data.length > 0 &&
            x.attributes.parent.data &&
            x.attributes.parent.data.attributes.slug === params &&
            x.attributes.type.includes('small')
        )
        .map((sl) => (
          <ListVideos
            color={sl.attributes.color}
            title={sl.attributes.name}
            time={sl.attributes.createdAt}
            data={swimlanesRDX
              .filter(
                (x) =>
                  x.attributes.parent.data &&
                  x.attributes.parent.data.attributes.slug === params &&
                  x.attributes.type.includes('small')
              )
              .map((a) => a.attributes.articles.data)}
            vertical={false}
          />
        ))}
      <BrowseTags />
    </div>
  );
};

export default WineryUpdate;
