import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import VideoPreview from '../components/VideoTagsPreview';
import { setSeeVideo } from '../redux/Reducer';
import colors from '../assets/colors';

const SwimlanesScreen = () => {
  const { articlesRDX, selectedTag } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [articles, setArticles] = useState(null);

  useEffect(() => {
    dispatch(setSeeVideo(true));
    const allTagsFiltered = articlesRDX
      .filter((x) => x.attributes.swimlanes.data.length > 0)
      .filter((x) =>
        x.attributes.swimlanes.data.find(
          (x) => x.attributes.name === selectedTag
        )
      );
    setArticles(allTagsFiltered);
  }, [selectedTag]);

  return (
    <div style={{ height: 'auto' }}>
      <Row
        justify={'space-between'}
        align={'stretch'}
        style={{
          zIndex: 20,
          position: 'sticky',
          top: 0,
          width: '100%',
          padding: '22px 23px',
          backgroundColor: colors.dark_slate_blue,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <LeftOutlined
          style={{
            color: '#fff',
            strokeWidth: '90',
            stroke: 'white',
          }}
          onClick={() => {
            navigate(-1);
            dispatch(setSeeVideo(false));
          }}
        />
        <span
          key={selectedTag}
          style={{
            color: '#fff',
            fontSize: 16,
            fontWeight: 700,
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
          onClick={() => {
            navigate(-1);
            dispatch(setSeeVideo(false));
          }}
        >
          {selectedTag}
        </span>
        <div />
      </Row>
      <div style={{ paddingTop: 20 }}>
        <Row>
          {articles &&
            articles.map((x, idx) => {
              return (
                <Col md={12} xs={24}>
                  <VideoPreview
                    idx={idx}
                    key={idx}
                    article={x.attributes}
                    vertical={false}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
};

export default SwimlanesScreen;
