import React from 'react';
import colors from '../../assets/colors';

const CustomTitle = ({
  title,
  fontSize = 20,
  fontFamily = 'SF Pro Display',
  color = '#343E47',
  fontWeight = 700,
}) => {
  return (
    <div
      style={{
        fontWeight,
        fontSize,
        fontFamily,
        color,
      }}
    >
      {title}
    </div>
  );
};

export default CustomTitle;
