import React, { useEffect, useRef, useState } from 'react';
import { Divider, Row, Tag, Grid } from 'antd';
import { DiscussionEmbed } from 'disqus-react';
import { useDispatch, useSelector } from 'react-redux';
import CustomImage from '../general/CustomImage';
import colors from '../../assets/colors';
import CustomTitle from '../general/customTitle';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useNavigate } from 'react-router-dom';
import {
  setSeeVideo,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
} from '../../redux/Reducer';
import { findTime } from '../../helpers/findTime';
import moment from 'moment';
import CustomVideo from '../general/CustomVideo';

const { useBreakpoint } = Grid;

const disqusConfig = {
  config: {
    identifier: 'unique-page-identifier',
    title: 'Page Title',
    // Optional: Add the URL of the page if it's different from the browser's URL
    // url: 'https://example.com/some-page',
  },
};

const Post = (props) => {
  const { selectedArticle, customerName } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [containerDisplay, setContainerDisplay] = useState('block');

  const { xs, sm, md, lg, xl } = useBreakpoint();
  const mobileView = xs && !sm && !md && !lg && !xl;

  useEffect(() => {
    // const videoElement = videoRef.current;

    // videoElement.addEventListener('loadedmetadata', () => {
    //   videoElement.videoWidth <= 550 &&
    //     window.innerWidth > 786 &&
    //     setContainerDisplay('flex');
    // });
  }, [selectedArticle]);

  return (
    <div
      style={{
        width: mobileView ? '100%' : '80%',
        margin: '0 auto',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 6px',
      }}
    >
      <div
        style={{
          display: containerDisplay,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 6px',
        }}
      >
        <CustomImage videoRef={videoRef} />
        <div
          style={{
            width: 'auto',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'start',
            paddingLeft: 20,
          }}
        >
          <br />
          <span
            style={{
              color: colors.dusty_grey,
              marginRight: 6,
              fontSize: 14,
            }}
          >
            {findTime(moment(selectedArticle?.createdAt))}
          </span>
          <CustomTitle
            title={selectedArticle?.name}
            fontWeight={400}
            fontFamily='Archer Bold'
            fontSize={24}
          />

          <Row style={{ paddingTop: 5 }}>
            {selectedArticle?.tags?.data?.map((x) => (
              <span
                key={x.attributes.name}
                style={{
                  backgroundColor: colors[x.attributes.color],
                  color: '#fff',
                  fontSize: 10,
                  padding: '1px 5px',
                  borderRadius: 2,
                  marginRight: 10,
                  textTransform: 'uppercase',
                  fontWeight: 700,
                }}
                onClick={() => {
                  navigate(
                    `/${customerName}/tags/${x.attributes.name
                      .split(' ')
                      .map((x) => x.toLowerCase())
                      .join('-')}`
                  );
                  dispatch(setSeeVideo(true));
                  dispatch(setSelectedTag(x.attributes));
                  dispatch(setSelectedTagForPreviousNavigation(null));
                }}
              >
                {x.attributes.name}
              </span>
            ))}
          </Row>

          <MarkdownPreview
            source={selectedArticle?.body}
            style={{
              fontFamily: 'SF Pro Display',
              marginTop: 10,
              marginBottom: 10,
              lineHeight: 1.5,
              backgroundColor: 'transparent',
              color: '#343E47',
            }}
          />
        </div>
      </div>
      <Divider style={{ margin: 0, backgroundColor: '#CCCCCC' }} />

      <div style={{ padding: 20, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 6px' }}>
        <DiscussionEmbed config={disqusConfig} shortname='wine-grower' />
      </div>
    </div>
  );
};

export default Post;
