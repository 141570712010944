import { Button, Divider, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setSeeVideo, setTitleMessage } from "../redux/Reducer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import colors from "../assets/colors";
import Mail from "../assets/Mail";
import SignInIcon from "../assets/SignInIcon";
import Lock from "../assets/Lock";
import ForgotPasswordIcon from "../assets/ForgotPasswordIcon";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = window.location.pathname;
  const [errorMessage, setErrorMessage] = useState("");

  const { customerName } = useSelector((state) => state);

  useEffect(() => {
    params === "/forgotPassword" && dispatch(setSeeVideo(true));
  }, [params]);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    axios
      .post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/auth/forgot-password`,
        {
          email: values.email,
        },
        { headers: { chinawall: process.env.REACT_APP_CHINAWALL } }
      )
      .then((res) => {
        // message.success("Reset request email sent. Please check your email.");
        dispatch(
          setMessage(
            "Reset request email sent. Please check your inbox or junk folder."
          )
        );
        dispatch(setTitleMessage("Reset Password"));
        dispatch(setSeeVideo(false));
        navigate(`/${customerName}/feedback`);
      })
      .catch((err) => {
        setErrorMessage("Something went wrong, please try again.");
        // message.error(err.message);
        // dispatch(setMessage("Please try again."));
        // dispatch(setTitleMessage("Something went wrong"));
        // navigate(`/${customerName}/feedback`);
      });
  };

  return (
    <div className="main-container">
      <div className="header-page-title">THE SET</div>

      <div className="form-container">
        <div className="header-icon">
          {/* <SignInIcon /> */}
          <ForgotPasswordIcon />
        </div>
        <Form
          form={form}
          name="forgotPassword"
          onFinish={onFinish}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          className="login-form"
        >
          <div className="form-title">Forgot Password</div>

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
            className="custom-form-item"
          >
            <Input
              className="custom-input"
              prefix={
                <div
                  style={{
                    marginRight: 5,
                    display: "flex",
                    padding: "3px 0",
                  }}
                >
                  <Mail />
                </div>
              }
              placeholder="Email Address"
            />
          </Form.Item>
          {errorMessage && (
            <div className="error-message-text">{errorMessage}</div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Send Reset Link
              </Button>
            </Form.Item>
          </div>
          <div className="register-text">
            <div>Already have account? </div>
            <a
              href={`/${customerName}`}
              className="register-text-link"
              style={{
                color: colors.limed_spruce,
              }}
            >
              Sign In
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default ForgotPassword;
