import React from "react";
import colors from "../assets/colors";
import SearchIcon from "../assets/SearchIcon";

const Search = ({ set }) => {
  return (
    <div style={{ padding: "0 14px" }}>
      <div
        style={{
          borderRadius: 115,
          backgroundColor: "#fff",
          border: `1px solid ${colors.search}`,
          padding: "10px 20px",
          width: "100%",
          display: "flex",
        }}
      >
        <SearchIcon />

        <input
          type="text"
          placeholder="Search"
          style={{
            marginLeft: 10,
            border: 0,
            backgroundColor: "transparent",
            width: "100%",
          }}
          onChange={({ target: { value } }) => {
            set(value.toString().toLowerCase());
          }}
        />
      </div>
    </div>
  );
};

export default Search;
