import { Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Video from './VideoPreview';
import CustomTitle from './general/customTitle';
import SeeAll from './general/seeAll';
import SeeAllVideos from './SeeAllVideos';
import { useSelector } from 'react-redux';
import { intersection } from 'lodash';
import './styles.css';

const ListVideosHorizontal = ({ data, title, vertical }) => {
  const { articlesRDX } = useSelector((state) => state);

  const [articles, setArticles] = useState(null);
  const [articlesLength, setArticlesLength] = useState(null);

  useEffect(() => {
    const allArticlesIds = intersection(
      data
        .reduce((acc, curr) => acc.concat(curr), [])
        .map((x) => x.attributes.uuid),
      articlesRDX.map((a) => a.attributes.uuid)
    );
    const allArticles =
      allArticlesIds &&
      allArticlesIds.length > 0 &&
      articlesRDX
        .filter((x) => allArticlesIds.includes(x.attributes.uuid))
        .map((x) => x);
    setArticles(allArticles);
  }, [articlesRDX, data]);

  useEffect(() => {
    setArticlesLength(
      articles &&
        articles.filter((article) =>
          article.attributes.swimlanes.data.some(
            (x) => x.attributes.name === title
          )
        ).length
    );
  }, [articles]);

  return (
    <div>
      <Row justify={'space-between'} align={'middle'}>
        <CustomTitle title={title} />
        {!!articlesLength && articlesLength > 3 && <SeeAll title={title} />}
      </Row>
      <br />
      <Row
        className='horizontal-list-row'
        style={{
          flexFlow: 'nowrap',
          overflowX: 'auto',
          width: '100%',
          paddingBottom: 20,
        }}
      >
        {(articles || [])
          .filter((a) =>
            a.attributes.swimlanes.data.some((x) => x.attributes.name === title)
          )
          .slice(0, 3)
          .map((article, idx, arr) => {
            return (
              <div key={article.id}>
                <Video
                  title={title}
                  vertical={vertical}
                  article={article.attributes}
                  index={idx}
                  arr={arr}
                />
              </div>
            );
          })}
        {!!articlesLength && articlesLength > 3 && (
          <div>
            <SeeAllVideos vertical={true} title={title} />
          </div>
        )}
      </Row>

      <Divider />
    </div>
  );
};

export default ListVideosHorizontal;
