import React from "react";

const Glass = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_876_1287)">
        <path
          d="M25.4994 22C26.1288 22 26.6742 21.7921 27.1378 21.3818L19.205 13.4485C19.0149 13.5847 18.8303 13.7174 18.656 13.8435C18.0622 14.2806 17.5803 14.6223 17.2107 14.8668C16.8407 15.1118 16.3486 15.3619 15.734 15.6171C15.1194 15.8723 14.5465 15.9999 14.0152 15.9999H13.9842C13.453 15.9999 12.8801 15.8728 12.2655 15.6171C11.6509 15.3619 11.1588 15.1123 10.7892 14.8668C10.4192 14.6223 9.93776 14.2812 9.344 13.8435C9.17828 13.722 8.99476 13.5888 8.79651 13.4464L0.861649 21.3818C1.32527 21.7921 1.87073 22 2.50007 22H25.4994Z"
          fill="#CCCCCC"
        />
        <path
          d="M1.57843 8.45339C0.984677 8.05789 0.458533 7.60444 0 7.09406V19.1639L6.99187 12.172C5.59288 11.1954 3.79078 9.95761 1.57843 8.4539V8.45339Z"
          fill="#CCCCCC"
        />
        <path
          d="M26.4373 8.45341C24.3094 9.89408 22.5002 11.134 21.0107 12.174L28 19.1634V7.09357C27.5516 7.59379 27.0311 8.04673 26.4373 8.4529V8.45341Z"
          fill="#CCCCCC"
        />
        <path
          d="M25.4994 0H2.50007C1.69789 0 1.08076 0.270952 0.648657 0.812348C0.216049 1.35374 0 2.03138 0 2.84373C0 3.4995 0.28671 4.21069 0.859622 4.97627C1.43253 5.74185 2.04205 6.34323 2.68765 6.78092C3.04198 7.03103 4.10951 7.77323 5.89078 9.00751C6.85258 9.67396 7.68882 10.255 8.40763 10.7557C9.02019 11.1828 9.54837 11.5523 9.98453 11.8589C10.0349 11.8939 10.1131 11.9504 10.2179 12.0251C10.3302 12.1059 10.4731 12.2086 10.6489 12.3357C10.9875 12.5807 11.2686 12.7785 11.4928 12.9295C11.7165 13.0809 11.9874 13.2497 12.3057 13.4373C12.6234 13.6244 12.9228 13.7657 13.2039 13.8592C13.485 13.9528 13.7453 14 13.9853 14H14.0163C14.2557 14 14.516 13.9533 14.7976 13.8592C15.0787 13.7657 15.3781 13.6249 15.6959 13.4373C16.0136 13.2497 16.284 13.0804 16.5087 12.9295C16.7329 12.7785 17.014 12.5802 17.3526 12.3357C17.528 12.2086 17.6703 12.1059 17.7832 12.0256C17.8879 11.9504 17.9667 11.8944 18.017 11.8594C18.3566 11.623 18.8863 11.2549 19.598 10.7603C20.8932 9.86002 22.8011 8.53576 25.3291 6.78092C26.0896 6.24969 26.7245 5.60917 27.2354 4.85935C27.7453 4.10953 28.0005 3.3231 28.0005 2.50008C28.0005 1.81228 27.7529 1.22411 27.2583 0.734062C26.7632 0.244518 26.1771 0 25.5004 0L25.4994 0Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_876_1287">
          <rect width="28" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Glass;
