import React from 'react';
import colors from '../../assets/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setSeeVideo,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
} from '../../redux/Reducer';

const SeeAll = ({ hasTag, title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerName, selectedTag } = useSelector((state) => state);

  return (
    <span
      style={{
        color: colors.coral_tree,
        fontSize: 14,
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      onClick={() => {
        dispatch(setSeeVideo(true));
        if (hasTag) {
          navigate(
            `/${customerName}/tags/${selectedTag.tag.data.attributes.name
              .split(' ')
              .map((x) => x.toLowerCase())
              .join('-')}`
          );
          dispatch(setSelectedTagForPreviousNavigation(selectedTag));
          dispatch(setSelectedTag(selectedTag.tag.data.attributes));
        } else {
          dispatch(setSelectedTag(title));
          navigate(
            `/${customerName}/swimlanes/${title
              .split(' ')
              .map((x) => x.toLowerCase())
              .join('-')}`
          );
        }
      }}
    >
      See All
    </span>
  );
};

export default SeeAll;
