import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0742 11.9995C11.5155 12.5582 10.7748 12.865 9.98484 12.865C9.19488 12.865 8.45373 12.5578 7.89551 11.9995C7.33688 11.4417 7.03049 10.7002 7.03049 9.91061C7.03049 9.12105 7.3377 8.37949 7.89551 7.82127C8.45414 7.26264 9.19488 6.95584 9.98484 6.95584C10.7748 6.95584 11.516 7.26305 12.0742 7.82127C12.6328 8.37908 12.9392 9.12147 12.9392 9.91061C12.9392 10.6997 12.6328 11.4417 12.0742 11.9995ZM21 10.5C21 16.2894 16.2894 21 10.5 21C4.71064 21 0 16.2894 0 10.5C0 4.71064 4.71064 0 10.5 0C16.2894 0 21 4.70982 21 10.5ZM15.1717 14.1541L13.4351 12.413C13.9625 11.689 14.2513 10.8228 14.2513 9.9102C14.2513 8.77037 13.8075 7.69863 13.002 6.89268C12.1964 6.08672 11.1251 5.64252 9.98443 5.64252C8.84379 5.64252 7.77287 6.08672 6.96691 6.89268C6.16096 7.69863 5.71758 8.77037 5.71758 9.9102C5.71758 11.05 6.16178 12.1218 6.96691 12.9269C7.77287 13.7329 8.84379 14.1771 9.98443 14.1771C10.9065 14.1771 11.7817 13.8817 12.5102 13.3436L14.2419 15.0802C14.3698 15.209 14.538 15.273 14.7066 15.273C14.8751 15.273 15.0421 15.2094 15.17 15.0814C15.4268 14.8267 15.4268 14.4108 15.1713 14.1541H15.1717Z"
        fill="#0000004D"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default SearchIcon;
