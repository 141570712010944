import pruningImg from '../assets/images/pruning.png';
import budTrimmingImg from '../assets/images/bud-trimming.png';
import hedgingImg from '../assets/images/hedging.png';
import coverCropImg from '../assets/images/coverCrop.png';
import rootFlushImg from '../assets/images/rootFlush.png';
import shootThinningImg from '../assets/images/shootThinning.png';
import sprayProgramImg from '../assets/images/sprayProgram.png';
import watteringImg from '../assets/images/wattering.png';

const monthGrids = [
  { key: '1', name: 'JAN', label: 'January' },
  { key: '2', name: 'FEB', label: 'February' },
  { key: '3', name: 'MAR', label: 'March' },
  { key: '4', name: 'APR', label: 'April' },
  { key: '5', name: 'MAY', label: 'May' },
  { key: '6', name: 'JUN', label: 'June' },
  { key: '7', name: 'JUL', label: 'July' },
  { key: '8', name: 'AUG', label: 'August' },
  { key: '9', name: 'SEP', label: 'September' },
  { key: '10', name: 'OCT', label: 'October' },
  { key: '11', name: 'NOV', label: 'November' },
  { key: '12', name: 'DEC', label: 'December' },
];

const colorMatch = {
  sorrel_brown: 'rgb(192, 168, 121)',
  pastel_blue: 'rgb(99, 143, 158)',
  wine_rose: 'rgb(171, 117, 149)',
  dark_faded_green: 'rgb(76, 108, 118)',
  lavender_purple: 'rgb(120, 77, 101)',
  downy_blue: 'rgb(95, 195, 209)',
  dark_slate_blue: 'rgb(48, 81, 116)',
  dark_pastel_blue: 'rgb(77, 116, 145)',
  light_green: 'rgb(114, 161, 98)',
  dark_bouquet: 'rgb(157, 96, 98)',
  bouquet: 'rgb(75, 113, 62)',
  dark_blue: 'rgb(130, 94, 158)',
};

const iconMatch = {
  frutSet: shootThinningImg,
  rootFlush: rootFlushImg,
  shootThinning: shootThinningImg,
  sprayProgram: sprayProgramImg,
  budTrimming: budTrimmingImg,
  pruning: pruningImg,
  bloom: coverCropImg,
  coverCrop: coverCropImg,
  hedging: hedgingImg,
  watering: watteringImg,
};

export { monthGrids, colorMatch, iconMatch };
