import React, { useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import Latest from "../views/Latest";
import CustomPage from "../views/CustomPage";
import Post from "../components/Posts/Post";
import TagList from "../views/TagList";
import TagScreen from "../views/TagScreen";
import TaskScreen from "../views/TaskScreen";
import SwimlanesScreen from "../views/SwimlanesScreen";
import { useSelector } from "react-redux";
import Welcome from "../views/Welcome";
import Login from "../views/Login";
import Register from "../views/Register";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import TimeLine from "../views/Timeline";
import CustomVideo from "../components/general/CustomVideo";
import WaitScreen from "../views/WaitScreen";

const ProtectedRoute = ({ children }) => {
  const { userToken, customerName } = useSelector((state) => state);
  return userToken ? children : <Navigate to={`/${customerName}/`} />;
};

const RouterNavigator = ({ swimlanes, slug }) => {
  const { customerName } = useSelector((state) => state);
  return (
    <Routes>
      <Route path={"/"} element={<Welcome />} />
      <Route path={"/" + customerName + "/"} element={<Login />} />
      <Route path={"/" + customerName + "/register"} element={<Register />} />
      <Route
        path={"/" + customerName + "/forgotPassword"}
        element={<ForgotPassword />}
      />
        <Route
        path={"/" + customerName + "/feedback"}
        element={<WaitScreen />}
      />
      <Route
        path={"/" + customerName + "/resetPassword"}
        element={<ResetPassword />}
      />
      <Route
        path={"/:customer/latest"}
        element={
          <ProtectedRoute>
            <Latest />
          </ProtectedRoute>
        }
      />
      {swimlanes?.map((sl, idx) => (
        <Route
          key={sl}
          path={"/" + slug + "/" + sl}
          element={
            <ProtectedRoute>
              <CustomPage />
            </ProtectedRoute>
          }
        />
      ))}
      <Route
        path={"/:customer/article/:slug"}
        element={
          <ProtectedRoute>
            <Post />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/:customer/allTags"}
        element={
          <ProtectedRoute>
            <TagList />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/:customer/tags/:tag"}
        element={
          <ProtectedRoute>
            <TagScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/:customer/tasks/:task"}
        element={
          <ProtectedRoute>
            <TaskScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/:customer/swimlanes/:sl"}
        element={
          <ProtectedRoute>
            <SwimlanesScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path={"/:customer/timeline"}
        element={
          <ProtectedRoute>
            <TimeLine />
          </ProtectedRoute>
        }
      />

      <Route
        path={"/:customer/video"}
        element={
          <ProtectedRoute>
            <CustomVideo />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default RouterNavigator;
