import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  setSeeVideo,
  setSelectedArticle,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
  setSwimlaneArticleIndex,
} from '../../redux/Reducer';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import PlayButtonBig from '../../assets/PlayButtonBig';
import PauseButtonBig from '../../assets/PauseButtonBig';
import FullScreen from '../../assets/FullScreen';
import fullScreenImg from '../../assets/images/full-screen-toggle.png';
import pauseImg from '../../assets/images/pause.png';
import playImg from '../../assets/images/play.png';
import { Spin, message, Grid } from 'antd';
import './styles.css';

const { useBreakpoint } = Grid;

const CustomImage = ({ videoRef }) => {
  const {
    selectedArticle,
    articlesRDX,
    selectedTag,
    previousSelectedTag,
    customerName,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLandscape, setIsLandscape] = useState(true);
  const [videoCanStart, setVideoCanStart] = useState(false);
  const videoCanStartRef = useRef(false);
  const [loaderActive, setLoaderActive] = useState(false);

  const { xs, sm, md, lg, xl } = useBreakpoint();
  const mobileView = xs && !sm && !md && !lg && !xl;

  const articlesOnListForPlay = useMemo(
    () =>
      selectedTag?.timeline
        ? articlesRDX.filter(
            (rtc) => !!selectedTag.articles.data.find((a) => a.id === rtc.id)
          )
        : selectedTag?.name
        ? articlesRDX.filter((atc) =>
            atc.attributes.tags.data.some(
              (dt) => dt.attributes.name === selectedTag.name
            )
          )
        : articlesRDX.filter((atc) =>
            atc.attributes.swimlanes.data.some(
              (dt) => dt.attributes.name === selectedTag
            )
          ),
    [articlesRDX, selectedTag]
  );

  const handlePrevious = useCallback(() => {
    if (!articlesOnListForPlay.length) return;
    const currentArticleIdx = articlesOnListForPlay.findIndex(
      (atc) => atc.attributes.uuid === selectedArticle.uuid
    );

    if (currentArticleIdx <= 0) return;

    const previousArticle = articlesOnListForPlay[currentArticleIdx - 1];
    dispatch(setSelectedArticle(previousArticle.attributes));

    previousArticle?.attributes?.video?.data
      ? navigate(`/${customerName}/article/${previousArticle.attributes?.slug}`)
      : message.error('There is no content for this article');

    setTimeout(() => {
      window.location.reload();
    }, 0);
  }, [articlesOnListForPlay, selectedArticle]);

  const handleNext = useCallback(() => {
    if (!articlesOnListForPlay.length) return;
    const currentArticleIdx = articlesOnListForPlay.findIndex(
      (atc) => atc.attributes.uuid === selectedArticle.uuid
    );

    if (currentArticleIdx === articlesOnListForPlay.length - 1) return;

    const nextArticle = articlesOnListForPlay[currentArticleIdx + 1];
    dispatch(setSelectedArticle(nextArticle.attributes));

    nextArticle?.attributes?.video?.data
      ? navigate(`/${customerName}/article/${nextArticle.attributes?.slug}`)
      : message.error('There is no content for this article');

    setTimeout(() => {
      window.location.reload();
    }, 0);
  }, [articlesOnListForPlay, selectedArticle]);

  const isPreviousArticleAvailable = useMemo(() => {
    if (!articlesOnListForPlay.length) return false;
    const currentArticleIdx = articlesOnListForPlay.findIndex(
      (atc) => atc.attributes.uuid === selectedArticle.uuid
    );

    return currentArticleIdx <= 0 ? false : true;
  }, [articlesOnListForPlay, selectedArticle]);

  const isNextArticleAvailable = useMemo(() => {
    if (!articlesOnListForPlay.length) return false;

    const currentArticleIdx = articlesOnListForPlay.findIndex(
      (atc) => atc.attributes.uuid === selectedArticle.uuid
    );
    return currentArticleIdx === articlesOnListForPlay.length - 1
      ? false
      : true;
  }, [articlesOnListForPlay, selectedArticle]);

  useEffect(() => {
    dispatch(setSeeVideo(true));
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    videoElement.addEventListener('canplay', () => {
      setLoaderActive(false);
    });
    videoElement.addEventListener('waiting', (e) => {
      if (!videoCanStartRef.current) return;
      setLoaderActive(true);
    });

    videoElement.addEventListener('loadedmetadata', () => {
      videoCanStartRef.current = true;
      setVideoCanStart(true);
      setIsLandscape(videoElement.videoWidth > videoElement.videoHeight);

      if (videoElement.videoWidth <= 350 && window.innerWidth > 786) {
        videoRef.current.style.width = '350px';
      }

      if (
        window.innerWidth < 786 &&
        selectedArticle.video.data?.attributes.caption >= 350
      ) {
        videoRef.current.style.width = '100%';
        videoRef.current.style.height = '100vh';
      }
    });

    videoElement.addEventListener('play', () => {
      setIsPlaying(true);
    });

    videoElement.addEventListener('pause', () => {
      setIsPlaying(false);
    });

    videoElement.addEventListener('fullscreenchange', handleFullscreenChange);
    videoElement.addEventListener(
      'webkitendfullscreen',
      handleFullscreenChange
    );

    videoRef.current.setAttribute('playsInline', 'playsInline');
    videoRef.current.play();

    return () => {
      // Clean up event listener
      videoElement.removeEventListener('loadedmetadata', () => {});
      videoElement.removeEventListener('fullscreenchange', () => {});
      videoElement.removeEventListener('webkitendfullscreen', () => {});
      videoElement.removeEventListener('play', () => {});
      videoElement.removeEventListener('pause', () => {});
      videoElement.removeEventListener('canplay', () => {});
      videoElement.removeEventListener('waiting', () => {});
    };
  }, [selectedArticle]);

  const handleFullscreenChange = () => {
    if (!document.fullscreenElement) {
      videoRef.current.removeAttribute('controls');
      videoRef.current.setAttribute('playsInline', 'playsInline');
    }
  };

  const toggleFullscreen = () => {
    if (!videoRef.current) return;

    // if (
    //   document.fullscreenElement ||
    //   document.webkitFullscreenElement ||
    //   document.mozFullScreenElement
    // ) {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen();
    //   } else if (document.webkitExitFullscreen) {
    //     document.webkitExitFullscreen();
    //   } else if (document.mozCancelFullScreen) {
    //     document.mozCancelFullScreen();
    //   } else if (document.msExitFullscreen) {
    //     document.msExitFullscreen();
    //   }
    // } else {
    videoRef.current.pause();
    videoRef.current.removeAttribute('playsInline');
    videoRef.current.play();

    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
    // }
  };

  return (
    <div
      style={{
        position: 'relative',
        width: isLandscape || window.innerWidth <= 1000 ? 'auto' : '50%',
      }}
    >
      <Spin
        spinning={!videoCanStart}
        className='initial-spinner'
        tip='Video is loading...'
        size='large'
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loaderActive && <Spin className='spinner' size='large' />}
        </div>
        <span
          style={{
            color: '#fff',
            position: 'absolute',
            top: 15,
            right: 15,
            fontSize: 20,
            fontWeight: 600,
            zIndex: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(-1);
            if (state?.goToPreviousNavigationTag) {
              dispatch(setSelectedTag(previousSelectedTag));
              dispatch(setSelectedTagForPreviousNavigation(null));
            }
          }}
        >
          X
        </span>
        <video
          ref={videoRef}
          disablePictureInPicture
          controlsList='nodownload noremoteplayback noplaybackrate'
          autoPlay
          preload='metadata'
          // playsInline
          style={{
            width: '100%',
            // maxWidth: '100%',
            // minWidth: 350,
            // height: 'auto',
          }}
          onClick={() => {
            setIsPlaying(!isPlaying);
            if (isPlaying) {
              videoRef.current.pause();
            }
            if (!isPlaying) {
              videoRef.current.play();
            }
          }}
        >
          <source src={selectedArticle?.video?.data?.attributes.url} />
        </video>

        {!!articlesOnListForPlay.length && (
          <div
            style={{
              position: 'absolute',
              top: 'unset',
              bottom: '50%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              userSelect: 'all',
              pointerEvents: 'all',
            }}
          >
            <div
              onClick={handlePrevious}
              style={{
                cursor: isPreviousArticleAvailable ? 'pointer' : 'normal',
                position: 'absolute',
                left: mobileView
                  ? '3%'
                  : !isLandscape && window.innerWidth <= 1000
                  ? '-12%'
                  : !isLandscape
                  ? '-15%'
                  : '-9%',
              }}
            >
              <LeftCircleFilled
                twoToneColor='#cecece'
                style={{
                  fontSize: 26,
                  color: mobileView ? 'rgb(255,255,255)' : 'rgb(67 67 67)',
                  opacity: isPreviousArticleAvailable ? 1 : 0.5,
                }}
              />
            </div>

            <div
              onClick={handleNext}
              style={{
                cursor: isNextArticleAvailable ? 'pointer' : 'normal',
                position: 'absolute',
                right: mobileView
                  ? '3%'
                  : !isLandscape && window.innerWidth <= 1000
                  ? '-51%'
                  : !isLandscape
                  ? '-115%'
                  : '-9%',
              }}
            >
              <RightCircleFilled
                twoToneColor='#cecece'
                style={{
                  fontSize: 26,
                  color: mobileView ? 'rgb(255,255,255)' : 'rgb(67 67 67)',
                  opacity: isNextArticleAvailable ? 1 : 0.5,
                }}
              />
            </div>
          </div>
        )}

        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            // top: "unset", TODO: check if this is needed
            backgroundImage:
              'linear-gradient(0deg,  #343E47 0%, rgba(52, 62, 71, 0) 100%)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
          }}
        >
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(!isPlaying);
              if (isPlaying) {
                videoRef.current.pause();
              }
              if (!isPlaying) {
                videoRef.current.play();
              }
            }}
          >
            {isPlaying ? (
              <img src={pauseImg} alt='pause' />
            ) : (
              <img src={playImg} style={{ height: 25 }} alt='play' />
            )}
          </span>

          <span
            style={{ width: 28, height: 28, zIndex: 11, cursor: 'pointer' }}
            onClick={toggleFullscreen}
          >
            <img src={fullScreenImg} alt='pause' />
          </span>
        </div>
      </Spin>
    </div>
  );
};

export default CustomImage;
