import React, { useState } from "react";
import axios from "axios";

import { Button } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSeeVideo, setUserToken, setCustomerName } from "../redux/Reducer";
import colors from "../assets/colors";
import Mail from "../assets/Mail";
import Lock from "../assets/Lock";
import SignInIcon from "../assets/SignInIcon";
import EmailIcon from "../assets/EmailIcon";
import BackArrowIcon from "../assets/BackArrowIcon";
import ErrorIcon from "../assets/ErrorIcon";
import ClockIcon from "../assets/ClockIcon";

const WaitScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = window.location.pathname;
  const search = window.location.search;

  const [token, setToken] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {
    customerRDX,
    userToken,
    customerName,
    swimlanesRDX,
    titleMessage,
    redirectionRoute,
    message,
  } = useSelector((state) => state);

  return (
    <div
      className="main-container"
      style={{
        padding: 0,
        textAlign: "center",
      }}
    >
      <div className="header-page-container">
        <a
          href={
            redirectionRoute === "login"
              ? `/${customerName}/`
              : `/${customerName}/register`
          }
          className="back-button"
        >
          <div>
            <BackArrowIcon />
          </div>
        </a>
        <div className="header-page-title">THE SET</div>
      </div>

      <div className="form-container">
        <div className="header-icon">
          {message?.includes("waitlist") ? (
            <ClockIcon />
          ) : message?.includes("verification") ? (
            <EmailIcon />
          ) : (
            <ErrorIcon />
          )}
        </div>
        <div className="feedback-page-title">{titleMessage}</div>
        <div className="feedback-page-message">{message}</div>

        <div
          style={{ color: "#999999" }}
          className="feedback-page-back-to-login"
        >
          <a href={`/${customerName}/`} className="register-text-link">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              <div className="login-form-button-text"> Back To Login</div>
            </Button>
          </a>
        </div>
        {message?.includes("Verify") || message?.includes("verification") ? (
          <div className="feedback-hint-message">
            Hint: Email not in your inbox? Check your spam folder.
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default WaitScreen;
