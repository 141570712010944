import "./App.css";
import { ConfigProvider } from "antd";
import Layout from "./layout";
import colors from "./assets/colors";
import { useSelector } from "react-redux";

function App() {

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.coral_tree,
        },
      }}
    >
      <Layout />
    </ConfigProvider>
  );
}

export default App;
