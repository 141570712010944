import { Row, Col, Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import timeLineImg from '../assets/images/timelineOnly.png';
import plusImg from '../assets/images/plus.png';
import minusImg from '../assets/images/minus.png';
import infoImg from '../assets/images/info.png';
import vineyard from '../assets/images/wineyard.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setSeeVideo, setSelectedTag } from '../redux/Reducer';
import { monthGrids, colorMatch, iconMatch } from './constants';
import './styles.css';
import BrowseTags from '../components/BrowseTags';

const { Panel } = Collapse;

const text = (
  <div className='timeline-text'>
    <span className='text-header-prefix'>Winegrower</span> is centered around
    the entire process of winemaking from vineyard to bottle. This view is a
    year-long view of the different phases involved in the creation of wine
    starting even before grapes are on the vine. <br />
    <br />
    <span className='text-header-prefix'>
      Click on each phase to see videos, updates and other interesting details
      about that part of the wine growing season.
    </span>
  </div>
);

const TimeLine = () => {
  const [panelSelected, setPanelSelected] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerName, timelineRDX, articlesRDX } = useSelector(
    (state) => state
  );

  useEffect(() => {
    dispatch(setSeeVideo(false));
  }, []);

  const onChange = (key) => {
    if (!key.length) setPanelSelected([]);
    else setPanelSelected(['1']);
  };

  const handle = (tasksGroup) => {
    const groupedTaskNames = tasksGroup.reduce((arr, task, idx) => {
      const emptyMonthsBetween =
        parseInt(task.monthsPeriod[0]) - arr.flat().length;
      if (emptyMonthsBetween > 1)
        arr.push(Array(emptyMonthsBetween - 1).fill({ name: 'empty' }));

      arr.push(Array(task.monthsPeriod.length).fill(task));

      if (idx === tasksGroup.length - 1 && arr.flat().length !== 12) {
        arr.push(Array(12 - arr.flat().length).fill({ name: 'empty' }));
      }
      return arr;
    }, []);

    return groupedTaskNames
      .map((groupedTaskNameArr, idx) => {
        const [task] = groupedTaskNameArr;
        if (task.name !== 'empty') {
          const taskRelatedArticlesFromTag = task.tag.data
            ? articlesRDX.filter((arc) =>
                arc.attributes.tags.data.some(
                  (dt) => dt.id === task.tag.data.id
                )
              )
            : [];

          return (
            <Col
              style={{
                height: 47,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: colorMatch[task.backgroundColor],
                paddingLeft: 8,
                borderRadius: 3,
                cursor: 'pointer',
              }}
              xs={groupedTaskNameArr.length * 2}
              onClick={() => {
                navigate(
                  `/${customerName}/tasks/${task.name
                    .split(' ')
                    .map((x) => x.toLowerCase())
                    .join('-')}`
                );
                dispatch(setSeeVideo(true));
                dispatch(setSelectedTag(task));
              }}
            >
              {iconMatch[task.icon] && (
                <img
                  src={iconMatch[task.icon]}
                  alt=''
                  style={{ height: 23, marginRight: 8 }}
                />
              )}
              <div style={{ color: '#fff', fontSize: 15, fontWeight: 700 }}>
                {task.name}
              </div>
              {!!taskRelatedArticlesFromTag.length && (
                <div
                  style={{
                    marginLeft: 10,
                    width: 30,
                    height: 30,
                    backgroundColor: '#00000040',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 50,
                    color: '#fff',
                    fontWeight: 700,
                  }}
                >
                  <span>{taskRelatedArticlesFromTag.length}</span>
                </div>
              )}
            </Col>
          );
        }
        const prevGroupedTasksLength = groupedTaskNames
          .slice(0, idx)
          .flat().length;

        return groupedTaskNameArr.map((_, idx) => (
          <Col
            style={{
              height: 47,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                (prevGroupedTasksLength + idx) % 2 === 0
                  ? 'rgb(244,242,242)'
                  : '#fff',
            }}
            xs={2}
          />
        ));
      })
      .flat();
  };

  return (
    <div>
      <div className='timeline-open-header'>
        <div className='timeline-header-container'>
          <div className='timeline-header-inner-container'>
            <div className='timeline-header-text'>Wine Growing Timeline</div>
          </div>
        </div>
      </div>
      <div
        className='items-container'
        style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
      >
        <Row
          style={{
            width: '180%',
            minWidth: 1600,
            borderTop: '2px solid rgb(226,226,226)',
          }}
        >
          {timelineRDX.length && (
            <>
              {monthGrids.map((mg, idx) => (
                <Col
                  xs={2}
                  key={mg.key}
                  style={{
                    height: 47,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor:
                      idx % 2 === 0 ? 'rgb(244,242,242)' : '#fff',
                  }}
                  className={
                    new Date().getMonth() + 1 === parseInt(mg.key)
                      ? 'selected'
                      : ''
                  }
                >
                  <div style={{ fontSize: 10, fontWeight: 600 }}>{mg.name}</div>
                </Col>
              ))}
              {timelineRDX.map((tasksGroup) => (
                <Row
                  style={{
                    width: '180%',
                    minWidth: 1600,
                  }}
                >
                  {handle(tasksGroup)}
                </Row>
              ))}
            </>
          )}
        </Row>
      </div>

      <Collapse
        onChange={onChange}
        className='collapse'
        accordion
        activeKey={panelSelected}
      >
        <Panel
          showArrow={false}
          header={
            <div className='timeline-header'>
              <div className='timeline-header-container'>
                <div className='timeline-header-inner-container'>
                  <img src={infoImg} alt='' />
                  <div className='timeline-header-text-panel-header'>
                    What is the Wine Growing Timeline?
                  </div>
                </div>
                <img
                  src={panelSelected[0] === '1' ? minusImg : plusImg}
                  alt=''
                />
              </div>
            </div>
          }
          key={'1'}
        >
          <div>
            <img src={vineyard} alt='' />
          </div>
          {text}
        </Panel>
      </Collapse>
      <br />
      <br />
      <BrowseTags />
    </div>
  );
};

export default TimeLine;
