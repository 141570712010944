const colors = {
  dark_slate_blue: "#3E6587",
  slate_blue: "#5A7C9B",
  coral_tree: "#AE7575",
  limed_spruce: "#343E47",
  dusty_grey: "#999999",
  faded_green: "#84AE75",
  search: "#0000004D",
};

export default colors;
