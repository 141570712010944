import timelineImg from '../assets/images/Timeline.png';
import homeImg from '../assets/images/Home.png';

const inlineMenuItems = [
  { key: 'latest', icon: <img src={homeImg} alt='' />, selected: false },
  {
    key: 'timeline',
    icon: <img src={timelineImg} alt='' />,
    selected: false,
  },
];

export { inlineMenuItems };
