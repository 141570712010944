import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchSwimlanes = createAsyncThunk(
  'myApp/swimlanes',
  async ({ baseUrl, token }) => {
    const response = await axios({
      url: `${baseUrl}/swimlanes?sort[0]=priority&populate=*&pagination[page]=1&pagination[pageSize]=1000`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }
);

export const fetchCustomers = createAsyncThunk(
  'myApp/customers',
  async ({ baseUrl, token, custName }) => {
    const response = await axios({
      url: `${baseUrl}/customers?populate=*`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: response.data, customerName: custName };
  }
);

export const fetchArticles = createAsyncThunk(
  'myApp/articles',
  async ({ baseUrl, token }) => {
    const response = await axios({
      url: `${baseUrl}/articles?populate=*&pagination[page]=1&pagination[pageSize]=1000`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }
);

export const fetchTimeline = createAsyncThunk(
  'myApp/timeline',
  async ({ baseUrl, token }) => {
    const response = await axios({
      url: `${baseUrl}/tasks?populate=*&pagination[page]=1&pagination[pageSize]=1000`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }
);
