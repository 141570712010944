import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Row, Col, Carousel } from 'antd';
import { intersection } from 'lodash';
import VideoPreview from '../components/VideoTagsPreview';
import EducationalVideoPreview from '../components/TimelineEducationalVideoPreview';
import TimelineTagRelatedList from '../components/HorizontalTimelineTagRelatedList';
import {
  setSeeVideo,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
} from '../redux/Reducer';
import { colorMatch, iconMatch, monthGrids } from './constants';
import './styles.css';

const TaskScreen = () => {
  const {
    articlesRDX,
    customerName,
    customerRDX,
    selectedTag,
    previousSelectedTag,
    timelineTasksRDX,
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentMonth = String(new Date().getMonth() + 1);

  const [educationalArticles, setEducationalArticles] = useState([]);
  const [tagRelatedArticles, setTagRelatedArticles] = useState([]);

  const [monthRelatedTasks, setMonthRelatedTasks] = useState([]);

  useEffect(() => {
    const eduArticles = articlesRDX.filter((rtc) =>
      selectedTag.articles.data.some((a) => a.id === rtc.id)
    );
    const tagRelArticles = articlesRDX.filter((rtc) =>
      rtc.attributes.tags.data.some((t) => t.id === selectedTag.tag.data?.id)
    );

    setEducationalArticles(eduArticles);
    setTagRelatedArticles(tagRelArticles);
  }, [selectedTag]);

  useEffect(() => {
    const tasks = timelineTasksRDX.filter((task) =>
      task.monthsPeriod.includes(currentMonth)
    );
    setMonthRelatedTasks(tasks);
  }, [timelineTasksRDX]);

  return (
    <div style={{ height: 'auto' }}>
      {/* cover */}
      <div
        className='header'
        style={
          customerRDX && {
            backgroundColor: '#fff',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${customerRDX?.cover?.data.attributes.formats.large.url})`,
            zIndex: 1,
            padding: 8,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }
        }
      />
      {/* header */}
      <Row
        justify={'space-between'}
        align={'stretch'}
        style={{
          zIndex: 20,
          position: 'sticky',
          top: 0,
          width: '100%',
          padding: '22px 23px',
          zIndex: 1,
          backgroundColor: colorMatch[selectedTag.backgroundColor],
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <LeftOutlined
          style={{ color: '#fff', strokeWidth: '90', stroke: 'white' }}
          onClick={() => {
            if (previousSelectedTag?.timeline) {
              dispatch(setSelectedTag(previousSelectedTag));
              dispatch(setSelectedTagForPreviousNavigation(null));
            }
            navigate(-1);
          }}
        />
        <span
          key={selectedTag.name}
          style={{
            color: '#fff',
            fontSize: 16,
            fontWeight: 700,
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          {selectedTag.name}
        </span>
        <div />
      </Row>
      {/* educational articles carousel */}
      <Carousel className='carousel-container'>
        {educationalArticles.map((eduArc, idx) => (
          <div key={idx}>
            <EducationalVideoPreview article={eduArc.attributes} />
          </div>
        ))}
      </Carousel>
      {/* tag related articles */}
      <div style={{ padding: 20 }}>
        <TimelineTagRelatedList
          data={tagRelatedArticles}
          title={selectedTag.tag.data?.attributes?.name}
        />
      </div>

      {/* mother related timeline */}
      <div
        style={{
          paddingTop: 20,
          fontFamily: 'SF Pro Display Medium',
          fontSize: 20,
          color: '#343E47',
          textAlign: 'center',
        }}
      >
        More Happening in {monthGrids.find((i) => i.key === currentMonth).label}
      </div>

      <div style={{ paddingTop: 20 }}>
        {monthRelatedTasks.map((task) => {
          const taskRelatedArticlesFromTag = task.tag.data
            ? articlesRDX.filter((arc) =>
                arc.attributes.tags.data.some(
                  (dt) => dt.id === task.tag.data.id
                )
              )
            : [];

          return (
            <div
              style={{
                height: 47,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: colorMatch[task.backgroundColor],
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(
                  `/${customerName}/tasks/${task.name
                    .split(' ')
                    .map((x) => x.toLowerCase())
                    .join('-')}`
                );
                dispatch(setSeeVideo(true));
                dispatch(setSelectedTagForPreviousNavigation(selectedTag));
                dispatch(setSelectedTag(task));
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {iconMatch[task.icon] && (
                  <img
                    src={iconMatch[task.icon]}
                    alt=''
                    style={{ height: 23, padding: '0 10px' }}
                  />
                )}
                <div style={{ color: '#fff', fontSize: 15, fontWeight: 700 }}>
                  {task.name}
                </div>
              </div>
              <div
                style={{
                  marginRight: 10,
                  width: 30,
                  height: 30,
                  backgroundColor: '#00000040',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 50,
                  color: '#fff',
                  fontWeight: 700,
                }}
              >
                <span>{taskRelatedArticlesFromTag.length}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TaskScreen;
