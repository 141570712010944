import { Divider, List, message } from "antd";
import React, { useEffect, useState } from "react";
import PlayButton from "../assets/PlayButton";
import colors from "../assets/colors";
import { intersection } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSeeVideo, setSelectedArticle } from "../redux/Reducer";
import { findTime } from "../helpers/findTime";
import moment from "moment";

const ListVideos = ({ data, title, color }) => {
  const { articlesRDX, customerName } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    const allArticlesIds = intersection(
      data
        .reduce((acc, curr) => acc.concat(curr), [])
        .map((x) => x.attributes.uuid),
      articlesRDX.map((a) => a.attributes.uuid)
    );
    const allArticles =
      allArticlesIds &&
      articlesRDX
        .filter((x) => allArticlesIds.includes(x.attributes.uuid))
        .map((x) => x);
    setArticles(allArticles);
  }, [data]);

  return (
    <div>
      <List
        itemLayout="vertical"
        size="medium"
        dataSource={articles ? articles : []}
        renderItem={(item, idx) => (
          <List.Item
            style={{ textAlign: "left", cursor: "pointer" }}
            key={item.title}
            onClick={() => {
              dispatch(setSeeVideo(true));
              dispatch(
                setSelectedArticle({
                  ...item.attributes,
                  time: findTime(moment(item.attributes.createdAt)),
                })
              );
              item.attributes.video.data
                ? navigate(
                  `/${customerName}/article/${item.attributes.slug}`
                )
                : message.error("There is no content for this article");
            }}
          >
            <List.Item.Meta
              avatar={
                <div
                  style={{
                    position: "relative",
                    backgroundColor: colors[item.color],
                    width: 70,
                    height: 70,
                    borderRadius: 16,
                  }}
                >
                  <img
                    width={80}
                    style={{ borderRadius: 16 }}
                    src={item.attributes.cover.data.attributes.url}
                    alt=""
                  />
                  <div style={{ position: "absolute", left: 30, top: 25 }}>
                    <PlayButton />
                  </div>
                </div>
              }
              title={
                <div>
                  <span style={{ color: "#999999", fontSize: 10 }}>
                    {findTime(moment(item.attributes.createdAt))}
                  </span>
                  <span
                    style={{
                      marginLeft: 4,
                      fontSize: 10,
                      backgroundColor:
                        idx === 0
                          ? "#75A0AE"
                          : idx % 2 === 0
                            ? "#7E599B"
                            : "#84AE75",
                      padding: 4,
                      color: "#fff",
                    }}
                  >
                    <span>{title}</span>
                  </span>
                </div>
              }
              description={item.attributes.name}
            />
            {item.content}
          </List.Item>
        )}
      />
      <Divider />
    </div>
  );
};

export default ListVideos;
