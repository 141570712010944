import React from 'react';
import { Card, Divider, Grid, Row, Col, message } from 'antd';
import PlayButton from '../assets/PlayButton';
import colors from '../assets/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSeeVideo,
  setSelectedArticle,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
} from '../redux/Reducer';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { findTime } from '../helpers/findTime';

const { useBreakpoint } = Grid;

const VideoPreview = ({ idx, vertical, article }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerName, selectedTag } = useSelector((state) => state);

  const { md, lg, xl } = useBreakpoint();
  const desktopScreen = md || lg || xl;

  const isEven = idx % 2 === 0;

  return (
    <div
      style={{
        cursor: 'pointer',
        flexDirection: 'column',
        position: 'relative',
        padding: `0 ${!desktopScreen ? '20px' : isEven ? '3px' : '20px'} 20px ${
          !desktopScreen ? '20px' : isEven ? '20px' : '3px'
        }`,
      }}
    >
      <div
        style={{
          width: '100%',
          minWidth: 300,
          maxWidth: 600,
          height: 'auto',
          margin: 'auto',
        }}
      >
        <Card
          hoverable
          style={{
            position: 'relative',
            borderRadius: 10,
            overflow: 'hidden',
            width: '100%',
            minWidth: 300,
            maxWidth: 600,
            minHeight: 200,
            maxHeight: 300,
            height: 'auto',
            backgroundImage: `linear-gradient(to bottom, transparent, transparent, #343E47), url(${article.cover.data.attributes.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          onClick={() => {
            dispatch(setSeeVideo(true));
            dispatch(setSelectedArticle(article));
            article.video.data
              ? navigate(`/${customerName}/article/${article.slug}`)
              : message.error('There is no content for this article');
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PlayButton />
          </div>
        </Card>

        <div
          style={{
            paddingTop: 5,
          }}
        >
          {article.createdAt && (
            <div
              onClick={() => {
                dispatch(setSeeVideo(true));
                dispatch(setSelectedArticle(article));
                article.video.data
                  ? navigate(`/${customerName}/article/${article.slug}`)
                  : message.error('There is no content for this article');
              }}
              style={{
                fontSize: 14,
                color: '#999999',
              }}
            >
              {findTime(moment(article.createdAt))}
            </div>
          )}
          <div
            onClick={() => {
              dispatch(setSeeVideo(true));
              dispatch(setSelectedArticle(article));
              article.video.data
                ? navigate(`/${customerName}/article/${article.slug}`)
                : message.error('There is no content for this article');
            }}
            style={{
              fontSize: 24,
              fontWeight: 400,
              fontFamily: 'Archer Bold',
              color: '#343E47',
              lineHeight: '28.8px',
            }}
          >
            {article.name}
          </div>
          <Row style={{ paddingTop: 10 }}>
            {article.tags.data.map((x, idx) => (
              <span
                style={{
                  backgroundColor: colors[x.attributes.color],
                  color: '#fff',
                  fontSize: 10,
                  padding: '1px 5px',
                  borderRadius: 2,
                  marginRight: 10,
                  textTransform: 'uppercase',
                  fontWeight: 700,
                }}
                onClick={() => {
                  navigate(
                    `/${customerName}/tags/${x.attributes.name
                      .split(' ')
                      .map((x) => x.toLowerCase())
                      .join('-')}`,
                    {
                      state: {
                        previousSelectedTag:
                          typeof selectedTag === 'string'
                            ? selectedTag
                            : undefined,
                      },
                    }
                  );
                  dispatch(setSeeVideo(true));
                  dispatch(setSelectedTagForPreviousNavigation(selectedTag));
                  dispatch(setSelectedTag(x.attributes));
                }}
              >
                {x.attributes.name}
              </span>
            ))}
          </Row>
        </div>
      </div>
      <Row>
        <Col md={0} xs={24}>
          <div
            style={{
              marginTop: 20,
              marginLeft: 10,
              marginRight: 10,
              border: '1px solid #cccccc',
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default VideoPreview;
