import React from "react";

const Glass = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_876_1302)">
        <path
          d="M1.41888 10.4998H1.92568V8.12857C1.92622 3.74351 5.40915 0.0795924 9.76241 0.00160253C9.88163 -0.000534177 10.119 -0.000534177 10.2382 0.00160253C14.5915 0.0795924 18.0744 3.74351 18.0744 8.12911V10.5003H18.5817C19.3633 10.5003 20.0006 11.3059 20.0006 12.301V24.1934C20.0006 25.1875 19.3633 26.0005 18.5817 26.0005H1.41941C0.637825 26.0005 0.000577927 25.1875 0.000577927 24.1934V12.301C0.000577927 11.3059 0.637825 10.5003 1.41941 10.5003L1.41888 10.4998ZM8.39223 18.2347V21.8281C8.39223 22.2394 8.73705 22.5877 9.14923 22.5877H10.8509C11.263 22.5877 11.6079 22.2394 11.6079 21.8281V18.2347C12.0077 17.8415 12.2403 17.2977 12.2403 16.6962C12.2403 15.5568 11.3587 14.5772 10.2377 14.5323C10.119 14.5275 9.8811 14.5275 9.76188 14.5323C8.64082 14.5777 7.75926 15.5568 7.75926 16.6962C7.75926 17.2977 7.99181 17.8415 8.3917 18.2347H8.39223ZM5.26801 10.4998H14.7326V8.12857C14.7326 5.51645 12.6081 3.35623 10.0003 3.35623C7.39252 3.35623 5.26801 5.51645 5.26801 8.12857V10.4998Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_876_1302">
          <rect
            width="20"
            height="26"
            fill="white"
            transform="matrix(-1 0 0 1 20 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Glass;
