import { Button, Form, Input } from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSeeVideo,
  setMessage,
  setTitleMessage,
  setRedirectionRoute,
} from "../redux/Reducer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import colors from "../assets/colors";
import SignInIcon from "../assets/SignInIcon";

import validator from "validator";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = window.location.pathname;
  const { customerName } = useSelector((state) => state);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    params === `/${customerName}/register` && dispatch(setSeeVideo(true));
  }, [params]);

  console.log({ params });
  const [form] = Form.useForm();
  const onFinish = (values) => {
    axios
      .post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/auth/signup`,
        {
          first_name: values.email,
          last_name: " ",
          password: values.password,
          email: values.email,
          username: values.email,
        },
        { headers: { chinawall: process.env.REACT_APP_CHINAWALL } }
      )
      .then((res) => {
        dispatch(setRedirectionRoute("login"));
        dispatch(setTitleMessage("Verify your email!"));
        dispatch(
          setMessage(
            `We have sent you an email. Click on the email verification link sent to ${values.email}`
          )
        );
        res && navigate(`/${customerName}/feedback`);
        //message.info('Registration was successful! Please check your email for a confirmation. Please do not forget to check your spam folder too.', 5);
        //res && navigate(`/${customerName}/`);

        dispatch(setSeeVideo(false));
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Conflict") {
          setErrorMessage(
            "This e-mail is already in use. Please choose another one."
          );
        } else {
          setErrorMessage(err?.response?.data?.message);
        }
        // dispatch(setRedirectionRoute("register"));
        // dispatch(setTitleMessage("Registration Error."));
        // dispatch(setMessage(err?.response?.data?.message));
        // if (!err?.response?.data?.message) {
        //   dispatch(setMessage("Please try again later."));
        // }
        // navigate(`/${customerName}/feedback`);
        //message.error(err?.response?.data?.message);
      });
  };

  const validatePassword = (rule, value, callback) => {
    const valid = validator.isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });

    if (!value || !valid) {
      callback(
        "Use an 8+ character combination of upper and lowercase letters, digits, and symbols."
      );
    } else {
      callback();
    }
  };

  return (
    <div className="main-container">
      <div className="header-page-title">THE SET</div>
      <div className="form-container">
        <div className="header-icon">
          <SignInIcon />
        </div>
        <Form
          form={form}
          name="register"
          className="login-form"
          onFinish={onFinish}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          scrollToFirstError
        >
          <div
            className="form-title"
            style={{
              marginTop: errorMessage ? "20px" : "0px",
              marginBottom: errorMessage ? "0px" : "25px",
            }}
          >
            Register
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Form.Item
              name="email"
              className="custom-form-item"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                placeholder="Enter your email"
                className="custom-input"
                style={{
                  padding: "10px 15px",
                  borderRadius: 4,
                }}
              />
            </Form.Item>

            {/* <div
              style={{
                display: window.innerWidth > 768 ? "block" : "flex",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
                style={{ width: window.innerWidth < 768 ? "49%" : "100%" }}
              >
                <Input
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    padding: "10px 15px",
                  }}
                  placeholder="i.e. John"
                />
              </Form.Item>

              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
                style={{ width: window.innerWidth < 768 ? "49%" : "100%" }}
              >
                <Input
                  style={{
                    width: "100%",
                    padding: "10px 15px",
                    borderRadius: 4,
                  }}
                  placeholder="i.e. Winegrower"
                />
              </Form.Item>
            </div> */}
            <Form.Item
              name="password"
              className="custom-form-item"
              rules={[{ validator: validatePassword }]}
              // hasFeedback
              style={{ borderRadius: 4 }}
            >
              <Input.Password
                placeholder="Enter a password"
                className="custom-input"
                style={{ padding: "10px 15px" }}
              />
            </Form.Item>
            {errorMessage && (
              <div className="error-message-text">{errorMessage}</div>
            )}
            {/* 
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              style={{ borderRadius: 4 }}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="***********"
                style={{ padding: "10px 15px" }}
              />
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please accept the Terms of use to register"
                          )
                        ),
                },
              ]}
              style={{ textAlign: "center" }}
            >
              <Checkbox>
                I have read and agree to the{" "}
                <a
                  href="https://www.winegrower.co/terms-and-conditions"
                  target="_blank"
                  style={{
                    fontSize: 14,
                    color: colors.limed_spruce,
                    textDecorationLine: "underline",
                  }}
                >
                  terms of use
                </a>
              </Checkbox>
            </Form.Item> */}
            <Form.Item className="custom-form-button">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                <div className="login-form-button-text">Register</div>
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <a
                href={`/${customerName}/forgotPassword`}
                style={{
                  display: "block",
                  fontSize: 14,
                  color: colors.limed_spruce,
                  textDecorationLine: "underline",
                }}
              >
                Forgot password
              </a>
            </Form.Item> */}
          </div>
          <div className="register-text">
            <div>Already have an account? </div>
            <a
              href={`/${customerName}`}
              className="register-text-link"
              style={{
                color: colors.limed_spruce,
              }}
            >
              Sign in
            </a>
          </div>
        </Form>
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{}}>
          <div
            style={{
              fontSize: 24,
              fontFamily: "Archer",
              fontWeight: "600",
            }}
          >
            Register
          </div>
          <div style={{ color: "#999999" }}>
            Have an Account?{" "}
            <a
              href={`/${customerName}/`}
              style={{
                fontSize: 14,
                color: colors.limed_spruce,
                textDecorationLine: "underline",
              }}
            >
              Go to Login
            </a>
          </div>
        </div>
      </div>

      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{
          residence: ["zhejiang", "hangzhou", "xihu"],
          prefix: "86",
        }}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="Email Address"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input
            placeholder="i.e. john@winegrower.com"
            style={{
              padding: "10px 15px",
              borderRadius: 4,
            }}
          />
        </Form.Item>

        <div
          style={{
            display: window.innerWidth > 768 ? "block" : "flex",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input your first name!",
              },
            ]}
            style={{ width: window.innerWidth < 768 ? "49%" : "100%" }}
          >
            <Input
              style={{ width: "100%", borderRadius: 4, padding: "10px 15px" }}
              placeholder="i.e. John"
            />
          </Form.Item>

          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please input your last name!",
              },
            ]}
            style={{ width: window.innerWidth < 768 ? "49%" : "100%" }}
          >
            <Input
              style={{ width: "100%", padding: "10px 15px", borderRadius: 4 }}
              placeholder="i.e. Winegrower"
            />
          </Form.Item>
        </div>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
          style={{ borderRadius: 4 }}
        >
          <Input.Password
            placeholder="***********"
            style={{ padding: "10px 15px" }}
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          style={{ borderRadius: 4 }}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="***********"
            style={{ padding: "10px 15px" }}
          />
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Please accept the Terms of use to register")
                    ),
            },
          ]}
          style={{ textAlign: "center" }}
        >
          <Checkbox>
            I have read and agree to the{" "}
            <a
              href="https://www.winegrower.co/terms-and-conditions"
              target="_blank"
              style={{
                fontSize: 14,
                color: colors.limed_spruce,
                textDecorationLine: "underline",
              }}
            >
              terms of use
            </a>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              borderRadius: 50,
              minWidth: 165,
              margin: "0 auto",
              display: "block",
              fontWeight: "bold",
            }}
          >
            Register
          </Button>
        </Form.Item>

        <Divider style={{ margin: "0 0 15px 0", backgroundColor: "#EEEEEE" }} />
      </Form> */}
    </div>
  );
};
export default Register;
