import React from "react";

const SignInIcon = () => {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 0C13.6467 0 3.70175 8.143 0.86275 19.125H5.24875C7.96025 10.5081 16.0013 4.25 25.5 4.25C37.2215 4.25 46.75 13.7785 46.75 25.5C46.75 37.2215 37.2215 46.75 25.5 46.75C16.0034 46.75 7.96025 40.4919 5.24662 31.875H0.86275C3.70175 42.857 13.6467 51 25.5 51C39.5611 51 51 39.5611 51 25.5C51 11.4389 39.5611 0 25.5 0ZM24.106 15.4721L21.0502 18.5279L25.8974 23.375H0V27.625H25.8995L21.0502 32.4721L24.106 35.5279L32.606 27.0279L34.0638 25.5L32.6039 23.9721L24.106 15.4721Z"
        fill="#3D3D3D"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default SignInIcon;
