import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import JWPlayer from "@jwplayer/jwplayer-react";

import {
  setSeeVideo,
  setSelectedArticle,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
  setSwimlaneArticleIndex,
} from "../../redux/Reducer";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import PlayButtonBig from "../../assets/PlayButtonBig";
import PauseButtonBig from "../../assets/PauseButtonBig";
import FullScreen from "../../assets/FullScreen";
import fullScreenImg from "../../assets/images/full-screen-toggle.png";
import pauseImg from "../../assets/images/pause.png";
import playImg from "../../assets/images/play.png";
import { Spin, message, Grid } from "antd";
import "./styles.css";
import ReactPlayer from "react-player";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

const { useBreakpoint } = Grid;

const CustomVideo = () => {
  const {
    selectedArticle,
    articlesRDX,
    selectedTag,
    previousSelectedTag,
    customerName,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  // const { state } = useLocation();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLandscape, setIsLandscape] = useState(true);
  const [videoCanStart, setVideoCanStart] = useState(false);
  const videoCanStartRef = useRef(false);
  const [loaderActive, setLoaderActive] = useState(false);

  const { xs, sm, md, lg, xl } = useBreakpoint();
  const mobileView = xs && !sm && !md && !lg && !xl;

  const [state, setState] = useState({
    url: null,
    playing: true,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    volumeOpen: false,
    dropdownOpen: false,
    fullscreen: false,
  });

  const load = (url) => {
    setState({
      ...state,
      url,
      played: 0,
      loaded: 0,
    });
  };

  const player = useRef(null);
  const playerWrapper = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) player.current.player.pause();
    else player.current.player.play();
  };

  const articlesOnListForPlay = useMemo(
    () =>
      selectedTag?.timeline
        ? articlesRDX.filter(
            (rtc) => !!selectedTag.articles.data.find((a) => a.id === rtc.id)
          )
        : selectedTag?.name
        ? articlesRDX.filter((atc) =>
            atc.attributes.tags.data.some(
              (dt) => dt.attributes.name === selectedTag.name
            )
          )
        : articlesRDX.filter((atc) =>
            atc.attributes.swimlanes.data.some(
              (dt) => dt.attributes.name === selectedTag
            )
          ),
    [articlesRDX, selectedTag]
  );

  const handlePrevious = useCallback(() => {
    if (!articlesOnListForPlay.length) return;
    const currentArticleIdx = articlesOnListForPlay.findIndex(
      (atc) => atc.attributes.uuid === selectedArticle.uuid
    );

    if (currentArticleIdx <= 0) return;

    const previousArticle = articlesOnListForPlay[currentArticleIdx - 1];
    dispatch(setSelectedArticle(previousArticle.attributes));

    previousArticle?.attributes?.video?.data
      ? navigate(`/${customerName}/article/${previousArticle.attributes?.slug}`)
      : message.error("There is no content for this article");

    setTimeout(() => {
      window.location.reload();
    }, 0);
  }, [articlesOnListForPlay, selectedArticle]);

  const handleNext = useCallback(() => {
    if (!articlesOnListForPlay.length) return;
    const currentArticleIdx = articlesOnListForPlay.findIndex(
      (atc) => atc.attributes.uuid === selectedArticle.uuid
    );

    if (currentArticleIdx === articlesOnListForPlay.length - 1) return;

    const nextArticle = articlesOnListForPlay[currentArticleIdx + 1];
    dispatch(setSelectedArticle(nextArticle.attributes));

    nextArticle?.attributes?.video?.data
      ? navigate(`/${customerName}/article/${nextArticle.attributes?.slug}`)
      : message.error("There is no content for this article");

    setTimeout(() => {
      window.location.reload();
    }, 0);
  }, [articlesOnListForPlay, selectedArticle]);

  const isPreviousArticleAvailable = useMemo(() => {
    if (!articlesOnListForPlay.length) return false;
    const currentArticleIdx = articlesOnListForPlay.findIndex(
      (atc) => atc.attributes.uuid === selectedArticle.uuid
    );

    return currentArticleIdx <= 0 ? false : true;
  }, [articlesOnListForPlay, selectedArticle]);

  const isNextArticleAvailable = useMemo(() => {
    if (!articlesOnListForPlay.length) return false;

    const currentArticleIdx = articlesOnListForPlay.findIndex(
      (atc) => atc.attributes.uuid === selectedArticle.uuid
    );
    return currentArticleIdx === articlesOnListForPlay.length - 1
      ? false
      : true;
  }, [articlesOnListForPlay, selectedArticle]);

  useEffect(() => {
    dispatch(setSeeVideo(true));
  }, []);

  const toggleFullscreen = () => {
    if (!player.current) return;
    player.current.player.setFullscreen();
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div
      style={{
        position: "relative",
        width: isLandscape || window.innerWidth <= 1000 ? "auto" : "50%",
      }}
    >
      {/* <span
        style={{
          color: "#fff",
          position: "absolute",
          top: 15,
          right: 15,
          fontSize: 20,
          fontWeight: 600,
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(-1);
          if (state?.goToPreviousNavigationTag) {
            dispatch(setSelectedTag(previousSelectedTag));
            dispatch(setSelectedTagForPreviousNavigation(null));
          }
        }}
      >
        X
      </span> */}

      <JWPlayer
        ref={player}
        playlist="https://cdn.jwplayer.com/v2/playlists/NH0KhFq0"
        library="https://cdn.jwplayer.com/libraries/mAzEIK3H.js"
        controls
        play={state.playing}
        onPause={handlePause}
        onPlay={handlePlay}
        onEnded={handleEnded}
      />

      {!!articlesOnListForPlay.length && (
        <div
          style={{
            position: "absolute",
            top: "unset",
            bottom: "50%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            userSelect: "all",
            pointerEvents: "all",
          }}
        >
          <div
            onClick={handlePrevious}
            style={{
              cursor: isPreviousArticleAvailable ? "pointer" : "normal",
              position: "absolute",
              left: mobileView
                ? "3%"
                : !isLandscape && window.innerWidth <= 1000
                ? "-12%"
                : !isLandscape
                ? "-15%"
                : "-9%",
            }}
          >
            <LeftCircleFilled
              twoToneColor="#cecece"
              style={{
                fontSize: 26,
                color: mobileView ? "rgb(255,255,255)" : "rgb(67 67 67)",
                opacity: isPreviousArticleAvailable ? 1 : 0.5,
              }}
            />
          </div>

          <div
            onClick={handleNext}
            style={{
              cursor: isNextArticleAvailable ? "pointer" : "normal",
              position: "absolute",
              right: mobileView
                ? "3%"
                : !isLandscape && window.innerWidth <= 1000
                ? "-51%"
                : !isLandscape
                ? "-115%"
                : "-9%",
            }}
          >
            <RightCircleFilled
              twoToneColor="#cecece"
              style={{
                fontSize: 26,
                color: mobileView ? "rgb(255,255,255)" : "rgb(67 67 67)",
                opacity: isNextArticleAvailable ? 1 : 0.5,
              }}
            />
          </div>
        </div>
      )}

      {/* <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          // top: "unset", TODO: check if this is needed
          backgroundImage:
            "linear-gradient(0deg,  #343E47 0%, rgba(52, 62, 71, 0) 100%)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            handlePlayPause();
          }}
        >
          {isPlaying ? (
            <img src={pauseImg} alt="pause" />
          ) : (
            <img src={playImg} style={{ height: 25 }} alt="play" />
          )}
        </span>

        <span
          style={{ width: 28, height: 28, zIndex: 11, cursor: "pointer" }}
          onClick={toggleFullscreen}
        >
          <img src={fullScreenImg} alt="pause" />
        </span>
      </div> */}
    </div>
  );
};

export default CustomVideo;
