import React from 'react';
import { Card, message } from 'antd';
import PlayButton from '../assets/PlayButton';
import playButtonImg from '../assets/images/Video.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSeeVideo,
  setSelectedArticle,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
} from '../redux/Reducer';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { findTime } from '../helpers/findTime';
import './styles.css';

const EducationalVideoPreview = ({ article }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerName, selectedTag } = useSelector((state) => state);

  return (
    <div
      style={{
        cursor: 'pointer',
        flexDirection: 'column',
        position: 'relative',
        padding: 20,
      }}
    >
      <div
        style={{
          width: '100%',
          minHeight: '250px',
          height: 'auto',
          margin: 'auto',
        }}
      >
        <Card
          hoverable
          style={{
            position: 'relative',
            borderRadius: 10,
            overflow: 'hidden',
            width: '100%',
            minHeight: '250px',
            height: 'auto',
            backgroundImage: `linear-gradient(to bottom, transparent, transparent, #343E47), url(${article.cover.data.attributes.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          className='educational-card'
          onClick={() => {
            dispatch(setSeeVideo(true));
            dispatch(
              setSelectedArticle({
                ...article,
                time: findTime(moment(article?.createdAt)),
              })
            );
            article.video.data
              ? navigate(`/${customerName}/article/${article.slug}`)
              : message.error('There is no content for this article');
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={playButtonImg} />
              <div style={{ fontSize: 24, color: '#fff', fontWeight: 700 }}>
                {article.name}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default EducationalVideoPreview;
