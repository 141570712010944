import React, { useState } from "react";
import axios from "axios";

import { Button, Divider, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSeeVideo,
  setUserToken,
  setCustomerName,
  setMessage,
  setTitleMessage,
  setTitleType,
  setRedirectionRoute,
} from "../redux/Reducer";
import colors from "../assets/colors";
import Mail from "../assets/Mail";
import Lock from "../assets/Lock";
import SignInIcon from "../assets/SignInIcon";

import validator from "validator";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = window.location.pathname;
  const search = window.location.search;

  const [token, setToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { customerRDX, userToken, customerName, swimlanesRDX } = useSelector(
    (state) => state
  );

  useEffect(() => {
    params === `/${customerName}/` && dispatch(setSeeVideo(true));
  }, [params]);

  useEffect(() => {
    search && setToken(search.split("=")[1]);
  }, [search]);

  useEffect(() => {
    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_AUTH_BASE_URL}/email-verification/email-verify`,
          { token },
          { headers: { chinawall: process.env.REACT_APP_CHINAWALL } }
        )
        .then((res) => {
          dispatch(setTitleMessage("You’re on the Waitlist."));
          dispatch(
            setMessage(
              "Your email has been verified. You have joined our waitlist successfully! You will receive an email once you have been approved."
            )
          );
          navigate(`/${customerName}/feedback`);
          // message.success(
          //   "Your email has been verified successfully! Please wait for admin approval, you will be notified once accepted."
          // );
          // res && navigate(`/${customerName}/`);
        })
        .catch((err) => {
          if (err?.response?.data?.error === "verified_from_inbox") {
            dispatch(setTitleMessage("You’re on the Waitlist."));
            dispatch(setTitleType("waitlist"));
          } else {
            dispatch(setTitleMessage(""));
            dispatch(setMessage(err?.response?.data?.message));
          }

          navigate(`/${customerName}/feedback`);
          // message.error(err?.response?.data?.message);
        });
    }
  }, [token]);

  useEffect(() => {
    if (userToken && userToken != null) {
      navigate(`/${customerName}/latest`);
    }
  }, [userToken]);

  const onFinish = (values) => {
    axios
      .post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/auth/login`,
        { ...values },
        { headers: { chinawall: process.env.REACT_APP_CHINAWALL } }
      )
      .then((res) => {
        // dispatch(setUserToken(true));
        dispatch(setUserToken(res.data.jwt));
        dispatch(setRedirectionRoute("login"));
        dispatch(setSeeVideo(false));

        swimlanesRDX &&
          customerRDX &&
          res.data.jwt &&
          userToken &&
          navigate(`/${customerName}/latest`);
      })
      .catch((err) => {
        dispatch(setUserToken(null));
        if (err?.response?.data?.error === "waitlist") {
          dispatch(setTitleMessage("You’re on the Waitlist."));
          dispatch(setTitleType("waitlist"));
          navigate(`/${customerName}/feedback`);
        } else if (err?.response?.data?.error === "not_verified") {
          dispatch(setTitleMessage("Your account is not verified."));
          dispatch(setTitleType("verification"));
          navigate(`/${customerName}/feedback`);
        } else {
          dispatch(setTitleMessage("Authentication failed!"));
          dispatch(setTitleType(""));
        }
        dispatch(setRedirectionRoute("login"));
        dispatch(setMessage(err?.response?.data?.message));
        if (err?.response?.data?.error) {
          setErrorMessage(err?.response?.data?.message);
        } else {
          setErrorMessage("");
        }
        if (!err?.response?.data?.message) {
          dispatch(setMessage("Please try again later."));
        }
        // navigate(`/${customerName}/feedback`);
        // message.error(err?.response?.data?.message);
      });
  };

  const validatePassword = (rule, value, callback) => {
    const valid = validator.isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    if (!value || !valid) {
      callback("Please type a strong Password.");
    } else {
      callback();
    }
  };

  return (
    <div className="main-container">
      <div className="header-page-title">THE SET</div>

      <div className="form-container">
        <div className="header-icon">
          <SignInIcon />
        </div>
        <Form
          // style={{ marginTop: 30 }}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className="form-title">Sign In</div>
          <Form.Item
            name="email"
            className="custom-form-item"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              className="custom-input"
              prefix={
                <div
                  style={{
                    marginRight: 5,
                    display: "flex",
                    padding: "3px 0",
                  }}
                >
                  <Mail />
                </div>
              }
              placeholder="Email Address"
            />
          </Form.Item>
          <Form.Item
            name="password"
            className="custom-form-item"
            // rules={[
            //   { validator: validatePassword }
            // ]}
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
            ]}
          >
            <Input.Password
              className="custom-input"
              prefix={
                <div
                  style={{ marginRight: 10, display: "flex", padding: "3px" }}
                >
                  <Lock />
                </div>
              }
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {errorMessage && (
            <div className="error-message-text">{errorMessage}</div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Form.Item className="custom-form-button">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                <div className="login-form-button-text">Sign In</div>
              </Button>
            </Form.Item>
          </div>
          <div className="register-text">
            <div>A new member? </div>
            <a
              href={`/${customerName}/register`}
              className="register-text-link"
              style={{
                color: colors.limed_spruce,
              }}
            >
              Register
            </a>
          </div>
          <div className="register-text">
            <div></div>
            <a
              href={`/${customerName}/forgotPassword`}
              className="register-text-link"
              style={{
                color: colors.limed_spruce,
              }}
            >
              Forgot Password?
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Login;
