import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { intersection } from 'lodash';
import VideoPreview from '../components/VideoTagsPreview';
import {
  setSeeVideo,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
} from '../redux/Reducer';
import colors from '../assets/colors';

const TagScreen = () => {
  const { articlesRDX, customerName, selectedTag, previousSelectedTag } =
    useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [articles, setArticles] = useState(null);

  useEffect(() => {
    dispatch(setSeeVideo(true));
    const allTagsFiltered = articlesRDX
      .filter((x) => x.attributes.tags.data.length > 0)
      .filter((x) =>
        x.attributes.tags.data.find(
          (x) => x.attributes.name === selectedTag?.name
        )
      );
    setArticles(allTagsFiltered);
  }, [selectedTag]);

  return (
    <div style={{ height: 'auto' }}>
      <Row
        justify={'space-between'}
        align={'stretch'}
        style={{
          zIndex: 20,
          position: 'sticky',
          top: 0,
          width: '100%',
          padding: '22px 23px',
          zIndex: 1,
          backgroundColor: colors[selectedTag.color] || selectedTag.color,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <LeftOutlined
          style={{ color: '#fff', strokeWidth: '90', stroke: 'white' }}
          onClick={() => {
            if (previousSelectedTag) {
              navigate(-1);
              dispatch(setSelectedTag(previousSelectedTag));
              dispatch(setSelectedTagForPreviousNavigation(null));
            } else {
              navigate(`/${customerName}/`);
              dispatch(setSeeVideo(false));
            }
          }}
        />
        <span
          key={selectedTag.name}
          style={{
            color: '#fff',
            fontSize: 16,
            fontWeight: 700,
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          {selectedTag.name}
        </span>
        <div />
      </Row>
      <div style={{ paddingTop: 20 }}>
        <Row>
          {articles &&
            articles.map((x, idx) => {
              return (
                <Col md={12} xs={24}>
                  <VideoPreview
                    idx={idx}
                    key={idx}
                    article={x.attributes}
                    vertical={false}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
};

export default TagScreen;
