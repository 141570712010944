import React, { useState } from "react";
import axios from "axios";

import { Button, Divider, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSeeVideo, setUserToken , setCustomerName } from "../redux/Reducer";
import colors from "../assets/colors";
import Mail from "../assets/Mail";
import Lock from "../assets/Lock";

const Welcome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  useEffect(() => {
    window.location.href = "/thesetwines";
  }, [])

  

  return (
    <div style={{ padding: 24, textAlign: "center" }}>
    </div>
  );
};
export default Welcome;
