import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div>
          <img
            src={require("../../assets/images/new-wine-grower-logo.png")}
            height={"auto"}
            className="footer-img"
          />
        </div>
        <div className="footer-content-2">
          <div
            style={{
              color: "white",
              fontFamily: "circular-std",
            }}
          >
            Privacy Policy
          </div>
          <div className="footer-content-2-text">Terms & Conditions</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
