import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSwimlanes,
  fetchArticles,
  fetchCustomers,
  fetchTimeline,
} from "./thunks";
import { message } from "antd";

const initialState = {
  seeVideo: true,
  token: null,
  baseUrl: null,
  baseAuthUrl: null,
  titleMessage: null,
  message: null,
  titleType: null,
  redirectionRoute: null,
  chinawall: null,
  customerRDX: null,
  customersRDX: [],
  swimlanesRDX: [],
  articlesRDX: [],
  timelineRDX: [],
  timelineTasksRDX: [],
  selectedArticle: null,
  selectedTag: null,
  previousSelectedTag: null,
  userToken: null,
  swimalaneArticles: [],
  swimalaneArticleIndex: null,
  customerName: "thesetwines",
};

export const stateSlice = createSlice({
  name: "myApp",
  initialState,
  reducers: {
    setSeeVideo: (state, { payload }) => {
      state.seeVideo = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setBaseURL: (state, { payload }) => {
      state.baseUrl = payload;
    },
    setTitleMessage: (state, { payload }) => {
      state.titleMessage = payload;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
    },
    setTitleType: (state, { payload }) => {
      state.titleType = payload;
    },
    setRedirectionRoute: (state, { payload }) => {
      state.redirectionRoute = payload;
    },
    setBaseAuthURL: (state, { payload }) => {
      state.baseAuthUrl = payload;
    },
    setChinaWall: (state, { payload }) => {
      state.chinawall = payload;
    },
    setCustomerRDX: (state, { payload }) => {
      state.customerRDX = payload;
    },
    setCustomersRDX: (state, { payload }) => {
      state.customersRDX = payload;
    },
    setSwimLanesRDX: (state, { payload }) => {
      state.swimlanesRDX = payload;
    },
    setArticlesRDX: (state, { payload }) => {
      state.articlesRDX = payload;
    },
    setSelectedArticle: (state, { payload }) => {
      state.selectedArticle = payload;
    },
    setSelectedTag: (state, { payload }) => {
      state.selectedTag = payload;
    },
    setSelectedTagForPreviousNavigation: (state, { payload }) => {
      state.previousSelectedTag = payload;
    },
    setUserToken: (state, { payload }) => {
      state.userToken = payload;
    },
    setSwimlaneArticles: (state, { payload }) => {
      state.swimalaneArticles = payload;
    },
    setSwimlaneArticleIndex: (state, { payload }) => {
      state.swimalaneArticleIndex = payload;
    },
    setCustomerName: (state, { payload }) => {
      state.customerName = payload;
    },
    initializeState: (state) => {
      state.seeVideo = initialState.seeVideo;
      state.token = initialState.token;
      state.baseUrl = initialState.baseUrl;
      state.titleMessage = initialState.titleMessage;
      state.message = initialState.message;
      state.titleType = initialState.titleType;
      state.redirectionRoute = initialState.redirectionRoute;
      state.baseAuthUrl = initialState.baseAuthUrl;
      state.chinawall = initialState.chinawall;
      state.customerRDX = initialState.customerRDX;
      state.customersRDX = initialState.customersRDX;
      state.swimlanesRDX = initialState.swimlanesRDX;
      state.articlesRDX = initialState.articlesRDX;
      state.timelineRDX = initialState.timelineRDX;
      state.selectedArticle = initialState.selectedArticle;
      state.selectedTag = initialState.selectedTag;
      state.previousSelectedTag = initialState.previousSelectedTag;
      state.userToken = initialState.userToken;
      state.swimalaneArticles = initialState.swimalaneArticles;
      state.swimalaneArticleIndex = initialState.swimalaneArticleIndex;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSwimlanes.fulfilled, (state, { payload }) => {
      state.swimlanesRDX = payload.data;
    });
    builder.addCase(fetchSwimlanes.rejected, (_, { error }) => {
      message.error(error.message);
    });
    builder.addCase(fetchArticles.fulfilled, (state, { payload }) => {
      state.articlesRDX = payload.data;
    });
    builder.addCase(fetchArticles.rejected, (_, { error }) => {
      message.error(error.message);
    });
    builder.addCase(fetchCustomers.fulfilled, (state, { payload }) => {
      const { data, customerName } = payload;

      state.customerRDX = data.data.find(
        (x) => x.attributes.slug === customerName
      )?.attributes;
    });
    builder.addCase(fetchCustomers.rejected, (_, { error }) => {
      message.error(error.message);
    });
    builder.addCase(fetchTimeline.fulfilled, (state, { payload }) => {
      const transformedTasks = payload.data.map((task) => ({
        id: task.id,
        ...task.attributes,
        taskGroup: task.attributes.taskGroup[0],
        timeline: true,
      }));

      const taskGroupsIds = Array.from(
        new Set(transformedTasks.map((t) => t.taskGroup))
      ).sort((a, b) => parseInt(a) - parseInt(b));

      const taskGroups = taskGroupsIds.reduce((arr, taskGroupId) => {
        const foundTasksPerGroup = transformedTasks
          .filter((task) => task.taskGroup === taskGroupId)
          .sort(
            (a, b) => parseInt(a.monthsPeriod[0]) - parseInt(b.monthsPeriod[0])
          );
        arr.push(foundTasksPerGroup);
        return arr;
      }, []);

      state.timelineRDX = taskGroups;
      state.timelineTasksRDX = transformedTasks;
    });
    builder.addCase(fetchTimeline.rejected, (_, { error }) => {
      message.error(error.message);
    });
  },
});

export const {
  initializeState,
  setSeeVideo,
  setToken,
  setBaseURL,
  setTitleMessage,
  setMessage,
  setTitleType,
  setRedirectionRoute,
  setBaseAuthURL,
  setChinaWall,
  setCustomerRDX,
  setCustomersRDX,
  setSwimLanesRDX,
  setArticlesRDX,
  setSelectedArticle,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
  setUserToken,
  setSwimlaneArticles,
  setSwimlaneArticleIndex,
  setCustomerName,
} = stateSlice.actions;

export default stateSlice.reducer;
