import React, { useEffect, useState } from 'react';
import colors from '../assets/colors';
import Search from '../components/Search';
import { useNavigate } from 'react-router-dom';
import { Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSeeVideo,
  setSelectedTag,
  setSelectedTagForPreviousNavigation,
} from '../redux/Reducer';
import { uniqBy } from 'lodash';

const TagList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customerName, articlesRDX } = useSelector((state) => state);

  const [searchText, setSearchText] = useState(null);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const arr = articlesRDX
      .filter((o) => o.attributes.tags.data?.length > 0)
      .map((x) => x.attributes.tags.data);
    const arr2 = uniqBy(
      arr.reduce((acc, curr) => acc.concat(curr), []),
      'id'
    );
    setTags(
      arr2
        .map((a) => ({
          name: a.attributes.name,
          color: colors[a.attributes.color],
        }))
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    );
  }, [articlesRDX]);

  const groupTagsAlphabetically = (tags) => {
    const groupedTags = {};

    tags.forEach((tag) => {
      const firstLetter = tag.name.charAt(0).toUpperCase();
      if (!groupedTags[firstLetter]) {
        groupedTags[firstLetter] = [];
      }
      groupedTags[firstLetter].push(tag);
    });

    return groupedTags;
  };

  const groupedTags = groupTagsAlphabetically(tags);

  return (
    <div>
      <Row
        style={{
          textAlign: 'center',
          backgroundColor: '#fff',
          marginBottom: 15,
          padding: '24px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ alignSelf: 'center' }}></div>

        <div
          style={{
            color: colors.limed_spruce,
            fontWeight: 'bold',
            fontSize: 20,
          }}
        >
          All Tags
        </div>

        <div
          style={{
            fontSize: 24,
            fontWeight: '600',
            textShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(-1);
            dispatch(setSeeVideo(false));
          }}
        >
          X
        </div>
      </Row>

      <Search set={setSearchText} />

      {Object.entries(groupedTags)
        .filter((x) =>
          x[1].some((tagName) =>
            searchText
              ? tagName.name.toString().toLowerCase().includes(searchText)
              : tagName
          )
        )
        .map(([letter, tags], index) => (
          <div key={letter}>
            <div
              style={{
                backgroundColor: '#fff',
                padding: '11px 13px',
                marginTop: index === 0 ? 15 : 0,
                boxShadow: '1px 0px 3px rgba(0, 0, 0, 0.25)',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>{letter}</span>
            </div>
            {tags.map((tag, index, arr) => (
              <div
                key={tag.name}
                style={{
                  textAlign: 'center',
                  padding: '22px 23px',
                  backgroundColor: tag.color,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  zIndex: arr.length - index,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(
                    `/${customerName}/tags/${tag.name
                      .split(' ')
                      .map((x) => x.toLowerCase())
                      .join('-')}`
                  );
                  dispatch(setSelectedTag(tag));
                  dispatch(setSelectedTagForPreviousNavigation(null));
                }}
              >
                <span
                  key={tag.name}
                  style={{
                    color: '#fff',
                    fontSize: 16,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {tag.name}
                </span>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default TagList;
